import React, { useEffect, useState } from "react";
import createDeathNotice from "../Assets/createDeathNotice.png";
import fbIcon from "../Assets/facebook.png";
import twitterIcon from "../Assets/twitter.png";
import instaIcon from "../Assets/instagram.png";
import linkedinIcon from "../Assets/linkedin.png";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
function DeathNoticeSingle() {
  const initialState = {
    firstName: "",
    lastName: "",
    comments: "",
    noticeId: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [commentData, setCommentData] = useState([]);
  const [noticeDetails, setNoticeDetails] = useState({});
  const location = useLocation();
  const [noticeId, setNoticeId] = useState("");
  const [decriptionContent, setDescriptionContent] = useState(<></>);
  useEffect(() => {
    if (location.state.value) {
      getNoticeData(location.state.value.noticeid);
      allComments(location.state.value.noticeid);
      setNoticeId(location.state.value.noticeid);
    }
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addCommentAPI = () => {
    const payload = {
      noticeid: noticeId,
      firstname: formData.firstName,
      lastname: formData.lastName,
      message: formData.comments,
    };
    axios
      .post(
        "https://demise.ng/api/demise/comments/create_comments.php",
        payload
      )
      .then((response) => {
        if (response.data) {
          console.log("response", response.data);
          setFormData(initialState);
          allComments(noticeId);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const allComments = (noticeId) => {
    let payload = {
      noticeid: noticeId,
    };
    axios
      .post(
        "https://demise.ng/api/demise/comments/notice_comments.php",
        payload
      )
      .then((response) => {
        if (response.data) {
          console.log("Response", response.data);
          if (response.data.messages.length > 0) {
            setCommentData(response.data.messages);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getNoticeData = (id) => {
    const payload = {
      notice_id: id,
    };
    axios
      .post("https://demise.ng/api/demise/notice/notice_detail.php", payload)
      .then((response) => {
        if (response.data) {
          console.log("Response", response.data.notice_datail);
          let parseContent = JSON.parse(
            response.data.notice_datail.description
          );
          const data = convertFromRaw(parseContent);
          let html = stateToHTML(data);
          setDescriptionContent(html);
          setNoticeDetails(response.data.notice_datail);
        }
      });
  };

  const submitBtn = () => {
    addCommentAPI();
  };

  return (
    <>
      <UserHeader />
      <main className="homePage paddingY">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-12">
              <div className="row align-items-center justify-content-center  ">
                <h1 className="fw-bold text-center">
                  {noticeDetails.noticetype}
                </h1>
              </div>
              {noticeDetails && Object.keys(noticeDetails).length > 0 ? (
                <>
                  <div className="profile">
                    <img
                      src={noticeDetails.profileurl}
                      className="img-fluid rounded-3 mx-auto d-block mt-4 "
                      width={310}
                      height={300}
                      alt=""
                    />
                  </div>
                  <div className="detail text-center">
                    <h3 className="fw-semibold text-center my-4">
                      {noticeDetails.firstname} {noticeDetails.middlename}{" "}
                      {noticeDetails.lastname}{" "}
                      {noticeDetails.nickname
                        ? `(${noticeDetails.nickname})`
                        : ""}
                    </h3>
                    <p className="fw-medium">
                      From {noticeDetails.dob} | To {noticeDetails.dod}
                    </p>
                    <p className="fw-medium">
                      {noticeDetails.stateoforigin} / {noticeDetails.lga}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{ __html: decriptionContent }}
                    ></div>
                    <div className="advertiserImageGallery d-flex justify-content-center align-items-center mt-4">
                      {noticeDetails.files && noticeDetails.files.length > 0 ? (
                        noticeDetails.files.map((val) => (
                          <img src={val.imageurl} />
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                    {noticeDetails.fburl !== "" ||
                    noticeDetails.tweeturl !== "" ||
                    noticeDetails.instaurl !== "" ||
                    (noticeDetails.linkedinlink !== "" &&
                      noticeDetails.linkedinlink !== null) ? (
                      <div className="social-links mt-4">
                        <p>
                          Links to the social media accounts of{" "}
                          {noticeDetails.firstname} {noticeDetails.middlename}{" "}
                          {noticeDetails.lastname}
                        </p>
                        <div className="d-flex gap-3 justify-content-center mb-4 pt-3">
                          {noticeDetails.fburl !== "" && (
                            <a href={noticeDetails.fburl}>
                              <img
                                src={fbIcon}
                                width={60}
                                height={60}
                                alt="facebook icon"
                              />
                            </a>
                          )}

                          {noticeDetails.tweeturl !== "" && (
                            <a href={noticeDetails.tweeturl}>
                              <img
                                src={twitterIcon}
                                width={60}
                                height={60}
                                alt="twitter icon"
                              />
                            </a>
                          )}

                          {noticeDetails.instaurl !== "" && (
                            <a href={noticeDetails.instaurl}>
                              <img
                                src={instaIcon}
                                width={60}
                                height={60}
                                alt="insta icon"
                              />
                            </a>
                          )}
                          {noticeDetails.linkedinlink !== "" &&
                            noticeDetails.linkedinlink !== null && (
                              <a href={noticeDetails.linkedinlink}>
                                <img
                                  src={linkedinIcon}
                                  width={60}
                                  height={60}
                                  alt="linkedin icon"
                                />
                              </a>
                            )}
                        </div>
                        <p>Date published {noticeDetails.createddate}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="bg-white py-4 px-3 rounded-4 mt-5">
                    <h6 className="fw-bold text-center mb-0 py-lg-3">
                      Posted on behalf of the family by:{" "}
                      {noticeDetails.yourfirstname}{" "}
                      {noticeDetails.yourmiddlename}{" "}
                      {noticeDetails.yourlastname}{" "}
                      {noticeDetails.relationship
                        ? `(${noticeDetails.relationship})`
                        : ""}
                    </h6>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="row mt-4 mx-0">
                <div className="bg-white p-4 text-center rounded-4">
                  <div className="d-flex gap-1 justify-content-center">
                    <span class="fa-stack">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.facebook.com/sharer/sharer.php?u=https://demise.ng/all-death-notices"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                          class="fab fa-facebook-f fa-stack-1x fa-inverse"
                          style={{ backgroundColor: "#3b5998" }}
                        ></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/share?url=https://demise.ng/all-death-notices"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                          class="fab fa-twitter fa-stack-1x fa-inverse"
                          style={{ backgroundColor: "#58aded" }}
                        ></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/share?url=https://demise.ng/all-death-notices"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                          class="fab fa-instagram fa-stack-1x fa-inverse"
                          style={{ backgroundColor: "#833AB4" }}
                        ></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a
                        href="https://www.linkedin.com/sharing/share-offsite/?url=https://demise.ng/all-death-notices"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                          class="fab fa-linkedin-in fa-stack-1x fa-inverse"
                          style={{ backgroundColor: "#017bb4" }}
                        ></i>
                      </a>
                    </span>
                    <span class="fa-stack">
                      <a
                        href="https://pinterest.com/pin/create/button/?url=https://demise.ng/all-death-notices"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i class="fa-solid fa-square fa-stack-2x"></i>
                        <i
                          class="fab fa-pinterest-p fa-stack-1x fa-inverse"
                          style={{ backgroundColor: "#ca2027" }}
                        ></i>
                      </a>
                    </span>
                  </div>
                  <h6 className="fw-bold text-center mt-2 mb-0 ">
                    Share Notice
                  </h6>
                </div>
              </div>
              <div className="bg-white py-4 px-lg-5 px-3 rounded-4 mt-4">
                <h3 className="mb-4" style={{ fontFamily: "playball" }}>
                  Condolences
                </h3>
                {commentData &&
                  commentData.map((val) => (
                    <div className="mb-4">
                      <p className="fw-medium mb-2">
                        {val.firstname} {val.lastname}
                      </p>
                      <div className="border border-dark-subtle  rounded-3 p-3">
                        <p className="mb-0">{val.message}</p>
                      </div>
                    </div>
                  ))}
              </div>
              <hr className="my-lg-5" />
              <div className="bg-white py-4 px-lg-5 px-3 rounded-4 mt-4">
                <form className="row g-4">
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name *"
                      name="firstName"
                      value={formData.firstName}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name *"
                      name="lastName"
                      onChange={onChange}
                      value={formData.lastName}
                    />
                  </div>
                  <div className="col">
                    <textarea
                      class="form-control"
                      placeholder="Write Your Comment Here *"
                      rows="3"
                      onChange={onChange}
                      name="comments"
                      value={formData.comments}
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-end ">
                    <button
                      type="button"
                      class="commonButton py-2 px-5 mt-2 "
                      onClick={submitBtn}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
}
export default DeathNoticeSingle;
