import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { fileRoutes } from "../../../Global/FileRoutes";
import { globalData } from "../../../Global/GlobalData";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [btnDisable,setBtnDisable] = useState(false);

  const navigate = useNavigate();
  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const validation = () => {
    if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email Address",
      });
      return false;
    } else if (!formData.password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Password",
      });
      return false;
    }
    return true;
  };

  const login = () => {
    adminLoginAPI();
  };

  const adminLoginAPI = () => {
    const valid = validation();
    if (valid) {
      let payload = {
        email: formData.email,
        password: formData.password,
        usertypeid: 1,
      };
      axios
        .post("https://demise.ng/api/demise/account/user_login.php", payload)
        .then((response) => {
          console.log("Response", response.data);
          if (response.data.status === "success") {
            localStorage.setItem("token", response.data.token);
            let userData = {
              user_id:response.data.user_id,
              user_name:response.data.user_name,
              user_email:response.data.user_email,
              user_role:response.data.user_role
            }
            localStorage.setItem("userData",JSON.stringify(userData));
            navigate(fileRoutes.adminHome);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message,
            }).then((confirm) => {
              if (confirm) {
                if (response.data.message.includes(globalData.creator)) {
                  navigate(fileRoutes.advertiserLogin);
                } else {
                  navigate(fileRoutes.adminLogin);
                }
              }
            });
          }
        });
    }
  };

     /* On Blur */
     const onBlur = (event) => {
      const {name,value} = event.target;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    Swal.fire({
     text:'Please enter a valid email address',
     title:'Error',
     icon:'error'
    })
     setBtnDisable(true);
 }
 else {
   setBtnDisable(false)
 }
    }
  return (
    <main className="homePage paddingY min-vh-100 d-flex align-items-center justify-content-center ">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-xl-6 col-lg-8">
            <div className="bg-white rounded-4 p-md-5 p-4">
              <h1
                className="fw-medium fs-4 text-center mb-4 py-3 rounded-5  mx-xl-5"
                style={{ backgroundColor: "var(--secondary-color)" }}
              >
                Admin Login
              </h1>
              <form className="row g-4 mt-3">
                <div className="col-12">
                  <label class="form-label">Email *</label>
                  <input
                    type="email"
                    className="form-control admin"
                    placeholder="Enter the admin email"
                    name="email"
                    onChange={onChange}
                    value={formData.email}
                    onBlur={onBlur}
                  />
                </div>
                <div className="col-12">
                  <label class="form-label">Password *</label>
                  <input
                    type="password"
                    className="form-control admin"
                    placeholder="Enter the admin password"
                    name="password"
                    value={formData.password}
                    onChange={onChange}
                  />
                </div>
                <button
                  type="button"
                  class="commonButton py-3 px-4 w-100 mt-5 fs-5"
                  onClick={login}
                  disabled={btnDisable}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default AdminLogin;
