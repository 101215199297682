import React from "react";
import ServiceCard from "../../GlobalComponents/ServiceCard/index"
import serviceIcon from "../../../../Assets/serviceIcon.png"
import ambulance from "../../../../Assets/ambulance.png"
import caskets from "../../../../Assets/caskets.png"
import funeralVideo from "../../../../Assets/funeralVideo.png"
import graveMaintenance from "../../../../Assets/graveMaintenance.png"
import graveMarkers from "../../../../Assets/graveMarkers.png"
import headstonesMonuments from "../../../../Assets/headstonesMonuments.png"
import hotels from "../../../../Assets/hotels.png"
import memorialCards from "../../../../Assets/memorialCards.png"
import funeralProgrammes from "../../../../Assets/funeralProgrammes.png"
import funeralDirectors from "../../../../Assets/funeralDirectors.png"
import lifeInsurance from "../../../../Assets/lifeInsurance.png"
import { fileRoutes } from "../../../../../Global/FileRoutes";

const Index = () => {
  const serviceCard = [
    {
      title1: "Florists",
      title2: "7 services",
      imgSrc: serviceIcon,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Ambulance/Hearse Hire",
      title2: "7 services",
      imgSrc: ambulance,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Caskets",
      title2: "7 services",
      imgSrc: caskets,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Funeral Video & Streaming",
      title2: "7 services",
      imgSrc: funeralVideo,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Grave Maintenance",
      title2: "7 services",
      imgSrc: graveMaintenance,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Grave Markers, Plaques & Ornaments",
      title2: "7 services",
      imgSrc: graveMarkers,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Headstones & Monuments",
      title2: "7 services",
      imgSrc: headstonesMonuments,
      btnTitle: "View All",
      btnHref: fileRoutes.deathNoticeArchive
    },
    {
      title1: "Hotels",
      title2: "7 services",
      imgSrc: hotels,
      btnTitle: "View All",

    },
    {
      title1: "Memorial Cards & Keyrings",
      title2: "7 services",
      imgSrc: memorialCards,
      btnTitle: "View All",

    },
    {
      title1: "Funeral programmes and souvenirs",
      title2: "7 services",
      imgSrc: funeralProgrammes,
      btnTitle: "View All",

    },
    {
      title1: "Funeral directors",
      title2: "7 services",
      imgSrc: funeralDirectors,
      btnTitle: "View All",

    },
    {
      title1: "Life Insurance",
      title2: "7 services",
      imgSrc: lifeInsurance,
      btnTitle: "View All",

    },
  ]
  return (
    <section className="services-sec">
      <div className="container">
        <div className="row">
          <div className="col text-center ">
            <h2 class="display-5 fw-bold mb-3">Services Directory</h2>
            <p class=" fw-normal ">
            Find useful funeral-related services in your State
            </p>
          </div>
        </div>
        <div className="row mt-5 pt-4 g-4">
          <ServiceCard serviceCard={serviceCard} />
        </div>
      </div>
    </section>
  );
};

export default Index;
