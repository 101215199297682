import React from "react";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";

function Faqs() {
  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12 contentText practicalInfoContent">
                <h1 class="display-5 fw-bold mb-3 text-center">
                  Practical Information
                </h1>
                <p class="fw-medium text-center mb-5">
                Here you will find supportive information about loss and grieving
                </p>
                <div>
                <h5 className="fw-semibold mb-3">BEFORE DEATH</h5>
                  <h5 className="fw-semibold mb-3">Writing a Will</h5>
                  <p>
                    Putting your affairs in order is vital to help those who are
                    left behind when you die.
                    <br />A will is a witnessed document that sets out in
                    writing the deceased's wishes for his or her possessions,
                    called his or her 'estate', after death.
                  </p>
                  <p>
                    <em>Reasons for making a will</em>
                  </p>
                  <p>
                    A will can ensure that proper arrangements are made for your
                    dependants and that your property is distributed in the way
                    you wish after you die, subject to certain rights of spouses
                    and children.
                  </p>

                  <p>
                    Checklist of where your possessions are kept:
                  </p>
                  <p>
                    It is advisable to complete and keep updated a list of your
                    assets. You can use the following forms which are made
                    available to download below.
                  </p>
                  <p>
                    It will make it easier to identify and trace your assets
                    after you die. You should keep the list in a safe place.
                  </p>
                  <p>
                    The following forms, which can be downloaded, provide a
                    useful format to keep track of your possessions and assets.{" "}
                  </p>
                  <ul>
                    <li>
                      The form{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/form-where-my-possessions-are-kept(2)_f497936a104f4fde3d6f6bc6b5db362eadc04acee8c3ec61.doc"
                      >
                        <u>Where my possessions are kept</u>
                      </a>{" "}
                      MS Word format.
                    </li>
                    <li>
                      The form{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.citizensinformation.ie/docs/form-where-my-possessions-are-kept-pdf.pdf"
                      >
                        <u>Where my possessions are kept</u>
                      </a>{" "}
                      PDF.
                    </li>
                  </ul>
                  <h6 className="fw-semibold my-3"> Life Insurance</h6>
                  <p>
                  Securing a life insurance policy early is a crucial step in ensuring that sufficient funds are available for funeral expenses and to provide financial support to your family during their time of loss.
<br/>
It is also important to have an agreement in place with your bank for the release of your finances to your next of kin.
<br/>
In the services directory of this website, you will find information on Nigerian companies that offer life insurance options.

                  </p>
                  <h5 className="fw-semibold mb-3 mt-4">AFTER DEATH</h5>
                  <h6 className="fw-semibold mb-3"> Funeral Planning</h6>
                  <p>
                    Planning a funeral can be a challenging task, especially
                    during a time of grief. However, a checklist can help you
                    organise the necessary steps and ensure that you cover all
                    important aspects. Here’s a comprehensive checklist for
                    funeral planning:
                  </p>
                  <ul>
                    <li className="mb-2">
                      <span className="fw-semibold">Immediate Considerations:</span>
                      <ul className="ps-3 discList">
                        <li>Obtain a legal pronouncement of death.</li>
                        <li>
                          Decide on who will be taking charge of the
                          arrangements.
                        </li>
                        <li>Notify close friends and family members.</li>
                        <li>
                          Make sure the deceased person's assets are secured
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Services: funeral, memorial or wake keep</span>
                      <ul className="ps-3 discList">
                        <li>Decide on the nature of the burial ceremony</li>
                        <li>Select a casket</li>
                        <li>
                          Choose the type of service (traditional, religious or
                          military).
                        </li>
                        <li>
                          Decide on the location and type of the final resting
                          place.
                        </li>
                        <li>
                          Plan the funeral or memorial service (readings, music,
                          speakers).
                        </li>
                        <li>
                          Plan the content for the funeral program, guest
                          refreshments and souvenirs (if applicable)
                        </li>
                        <li>
                          Arrange for transportation (hearse, ambulance, family
                          transportation etc.).
                        </li>
                        <li>
                          Choose a grave marker or headstone, if applicable.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Legal and Administrative Tasks:</span>
                      <ul className="ps-3 discList">
                        <li>
                          Obtain death certificates (several copies may be
                          needed).
                        </li>
                        <li>Notify the deceased's employer, if applicable.</li>
                        <li>
                          Consult a lawyer to discuss the will and estate
                          matters, if applicable.
                        </li>
                        <li>
                          Notify banks, utility companies, and other relevant
                          institutions.
                        </li>
                        <li>
                          Cancel debit cards, subscriptions, and other services
                          in the deceased’s name.
                        </li>
                        <li>Forward mail or change the mailing address.</li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Obituary and Notices:</span>
                      <ul className="ps-3 discList">
                        <li>
                          Create an official death notice (e.g. on this site)
                          and synchronise all information, including social
                          media profiles on this platform.
                        </li>
                        <li>Write and submit an obituary to local media </li>
                        <li>
                          Create and send out funeral announcements or
                          invitations.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Floral Arrangements and Decorations:</span>
                      <ul className="ps-3 discList">
                        <li>Order floral arrangements.</li>
                        <li>Decide on decorations for the service venue. </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                    <span className="fw-semibold">Personal Touches:</span>
                      <ul className="ps-3 discList">
                        <li>Gather photos and memorabilia for the service.</li>
                        <li>Choose attire for the deceased.</li>
                        <li>
                          Plan any personal touches like a memory book, video,
                          or slideshow (You can post a link on the deceased's
                          profile on Demise.ng).
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Guest Considerations:</span>
                      <ul className="ps-3 discList">
                        <li>
                          Create a guest list and gather contact information.
                        </li>
                        <li>Consider accommodations for out-of-town guests.</li>
                        <li>
                          Arrange for post-service reception or gathering, if
                          desired.
                        </li>
                        <li>
                          Provide directions and accommodations information to
                          guests.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Financial Aspects:</span>
                      <ul className="ps-3 discList">
                        <li>Set a budget for the funeral and related costs.</li>
                        <li>
                          Explore payment options (savings, life insurance,
                          funeral insurance, etc.). More information on the
                          services directory of this site.{" "}
                        </li>
                        <li>
                          Keep track of all expenses for future reference.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Special Considerations:</span>
                      <ul className="ps-3 discList">
                        <li>Consider any religious or cultural customs.</li>
                        <li>
                          Address any specific wishes the deceased may have had.{" "}
                        </li>
                        <li>
                          Organise a special tribute or honour, if applicable.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Post-Funeral Tasks:</span>
                      <ul className="ps-3 discList">
                        <li>
                          Send thank-you notes or cards to those who offered
                          support or participated in the service.
                        </li>
                        <li>
                          Plan for the distribution of the deceased’s
                          belongings, if not already dictated by a will.{" "}
                        </li>
                        <li>
                          Consider grief counselling or support groups for
                          yourself and other family members.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h5 className="fw-semibold mb-3 mt-4">Writing a Eulogy</h5>
                  <p>
                    Writing a eulogy is a meaningful way to honour someone who
                    has passed away. It can be challenging, but it's also a
                    chance to celebrate their life and share your memories with
                    others. Here’s a step-by-step guide to help you craft a
                    heartfelt eulogy:
                  </p>
                  <ul>
                    <li className="mb-2">
                      <span className="fw-semibold">Reflect on the Person:</span> Spend some time thinking about the
                      person who has passed. What were their values, passions,
                      and most memorable traits? What stories or moments with
                      them stand out to you?
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Gather Information:</span> Talk to friends, family, and others
                      who were close to the deceased. They might have stories or
                      insights that you hadn't thought of or didn't know about.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Decide on the Tone:</span> Eulogies can range from solemn to
                      light-hearted. Your choice of tone should reflect both the
                      personality of the person you're honouring and the nature
                      of your relationship with them.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Create an Outline: A good structure might include:</span>
                      <ul className="ps-3 discList">
                        <li>
                          An introduction: Briefly introduce yourself and your
                          relationship with the deceased.
                        </li>
                        <li>
                          Body: Share memories, stories, and achievements.
                          Discuss their impact on you and others, and the legacy
                          they leave behind.
                        </li>
                        <li>
                          Conclusion: Offer a final farewell, a message of love
                          or a reflection on what they meant to you and others.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Be Personal and Honest:</span> Share your genuine feelings and
                      experiences. It’s okay to show emotion and even include
                      light-hearted anecdotes or quirks that made the person
                      unique.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Practice Your Speech:</span> Read your eulogy out loud,
                      preferably to someone who can provide feedback. This can
                      help smooth out any rough spots and help you get more
                      comfortable with delivering it.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Keep it Concise:</span> Aim for a speech that is about 5 to 10
                      minutes long. This is usually enough time to say what you
                      need to without being too lengthy.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Prepare for the Day:</span> On the day of the funeral or
                      memorial, bring a printed copy of your eulogy. Drink water
                      beforehand and take a moment to breathe and compose
                      yourself.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">Speak from the Heart:</span> When delivering the eulogy, remember
                      that your audience is there to support you. If you get
                      emotional, it's perfectly okay. Take your time.
                    </li>
                    <li className="mb-2">
                      <span className="fw-semibold">End with a Positive Note:</span> While it's a time of mourning,
                      ending on a note of gratitude or a positive reflection
                      about the person’s life can be comforting to you and the
                      audience.
                    </li>
                  </ul>
                  <p>
                    Remember, there's no right or wrong way to write a eulogy.
                    It's about expressing your feelings and sharing the essence
                    of the person you're remembering. Your sincerity is what
                    matters most.
                  </p>

                  <h5 className="fw-semibold mb-3 mt-4">
                    Bereavement support resources
                  </h5>
                  <p>
                    Here are some links to short, simple information leaflets
                    aimed at bereaved persons and those who support them.{" "}
                  </p>
                  <ul className="ps-3 discList">
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/00298-01-IHF-Understanding-GriefV4_d30a56b79483ed26c492719217acf01b6350b14cca76ba11.pdf"
                      >
                        <u>Understanding Grief </u>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/03-IHF-Grieving-the-Death-of-someone-close_5017cc814c3257d6a0ac7f1bec2ab05001d7f3b0c36b6dad.pdf"
                      >
                        <u>Grieving the death of someone close </u>{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/00298-09-IHF-Talking-to-Children-About-Death-V4_78ac565608cd8870fda59e2539fe6d00492a5d1386dbb9eb.pdf"
                      >
                        <u>Talking to Children About Traumatic Death </u>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/00298-02-IHF-Someone-you-care-about-is-bereaved-V4_8bf730878901769d8eace1d6722b3ef2b0c27a63d78d6082.pdf"
                      >
                        <u>Supporting Someone who is Grieving </u>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/Grief-at-Work-Leaflet_1bd89cffa47198f27d60cd0d42535f0d16ddcf5fb69aa80a.pdf"
                      >
                        <u>Grief in the Workplace </u>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://img-dedicated.rip.ie/00298-13-IHF-Christmas-Bereavement-V4_4585d4be4f1738b6d10b69bfa2317a0558bf534c68545503.pdf"
                      >
                        <u>Grief at Christmas</u>
                      </a>
                    </li>
                  </ul>
                  <h5 className="fw-semibold mb-3 mt-4">
                    Religious Quotes that Offer Comfort and Solace
                  </h5>
                  <p className="fw-semibold">
                    Here's a selection of supportive Bible verses that spans
                    both the Old and New Testaments:
                  </p>
                  <ul>
                    <li className="my-2">
                      <strong>Psalm 23:4</strong>
                    </li>
                    "Even though I walk through the valley of the shadow of
                    death, I will fear no evil, for you are with me; your rod
                    and your staff, they comfort me."
                    <li className="my-2">
                      <strong>John 14:1-3</strong>
                    </li>
                    "Do not let your hearts be troubled. You believe in God;
                    believe also in me. My Father’s house has many rooms; if
                    that were not so, would I have told you that I am going
                    there to prepare a place for you? And if I go and prepare a
                    place for you, I will come back and take you to be with me
                    that you also may be where I am."
                    <li className="my-2">
                      <strong>Revelation 21:4</strong>
                    </li>
                    "He will wipe every tear from their eyes. There will be no
                    more death or mourning or crying or pain, for the old order
                    of things has passed away."
                    <li className="my-2">
                      <strong>Psalm 34:18</strong>
                    </li>
                    "The Lord is close to the brokenhearted and saves those who
                    are crushed in spirit."
                    <li className="my-2">
                      <strong>Matthew 5:4</strong>
                    </li>
                    "Blessed are those who mourn, for they will be comforted."
                    <li className="my-2">
                      <strong>Romans 8:38-39</strong>
                    </li>
                    "For I am convinced that neither death nor life, neither
                    angels nor demons, neither the present nor the future, nor
                    any powers, neither height nor depth, nor anything else in
                    all creation, will be able to separate us from the love of
                    God that is in Christ Jesus our Lord."
                    <li className="my-2">
                      <strong>Psalm 116:15</strong>
                    </li>
                    "Precious in the sight of the Lord is the death of his
                    faithful servants."
                    <li className="my-2">
                      <strong>1 Corinthians 15:51-57</strong>
                    </li>
                    "Listen, I tell you a mystery: We will not all sleep, but we
                    will all be changed — in a flash, in the twinkling of an
                    eye, at the last trumpet. For the trumpet will sound, the
                    dead will be raised imperishable, and we will be changed."
                    <li className="my-2">
                      <strong>Psalm 73:26</strong>
                    </li>
                    "My flesh and my heart may fail, but God is the strength of
                    my heart and my portion forever."
                    <li className="my-2">
                      <strong>2 Corinthians 1:3-4</strong>
                    </li>
                    "Praise be to the God and Father of our Lord Jesus Christ,
                    the Father of compassion and the God of all comfort, who
                    comforts us in all our troubles, so that we can comfort
                    those in any trouble with the comfort we ourselves receive
                    from God."
                  </ul>
                  <p className="fw-semibold mt-5">
                    Here are some supportive verses from the Quran along with
                    their references:
                  </p>
                  <ul>
                    <li className="my-2">
                      <strong>Surah Al-Baqarah (2:156)</strong>
                    </li>
                    الَّذِينَ إِذَا أَصَابَتْهُم مُّصِيبَةٌ قَالُوا إِنَّا
                    لِلَّهِ وَإِنَّا إِلَيْهِ رَاجِعُونَ
                    <br />
                    "Who, when disaster strikes them, say, 'Indeed we belong to
                    Allah, and indeed to Him we will return.'"
                    <li className="my-2">
                      <strong>Surah Al-Baqarah (2:153)</strong>
                    </li>
                    يَا أَيُّهَا الَّذِينَ آمَنُوا اسْتَعِينُوا بِالصَّبْرِ
                    وَالصَّلاةِ إِنَّ اللَّهَ مَعَ الصَّابِرِينَ
                    <br />
                    "O you who have believed, seek help through patience and
                    prayer. Indeed, Allah is with the patient."
                    <li className="my-2">
                      <strong>Surah Yasin (36:12)</strong>
                    </li>
                    إِنَّا نَحْنُ نُحْيِي الْمَوْتَى وَنَكْتُبُ مَا قَدَّمُوا
                    وَآثَارَهُمْ ۚ وَكُلَّ شَيْءٍ أَحْصَيْنَاهُ فِي إِمَامٍ
                    مُّبِينٍ
                    <br />
                    "Indeed, We bring the dead to life, and We record what they
                    have put forth and their footprints. And everything We have
                    enumerated in a clear register."
                    <li className="my-2">
                      <strong>Surah Al-Imran (3:185)</strong>
                    </li>
                    كُلُّ نَفْسٍ ذَائِقَةُ الْمَوْتِ ۗ وَإِنَّمَا تُوَفَّوْنَ
                    أُجُورَكُمْ يَوْمَ الْقِيَامَةِ ۖ فَمَن زُحْزِحَ عَنِ
                    النَّارِ وَأُدْخِلَ الْجَنَّةَ فَقَدْ فَازَ ۗ وَمَا
                    الْحَيَاةُ الدُّنْيَا إِلَّا مَتَاعُ الْغُرُورِ
                    <br />
                    "Every soul will taste death, and you will only be given
                    your full compensation on the Day of Resurrection. So he who
                    is drawn away from the Fire and admitted to Paradise has
                    attained [his desire]. And what is the life of this world
                    except the enjoyment of delusion."
                    <li className="my-2">
                      <strong>Surah Al-Ankabut (29:57)</strong>
                    </li>
                    كُلُّ نَفْسٍ ذَائِقَةُ الْمَوْتِ ثُمَّ إِلَيْنَا تُرْجَعُونَ
                    <br />
                    "Every soul shall have a taste of death: and only on the Day
                    of Judgment shall you be paid your full recompense. Only he
                    who is saved far from the Fire and admitted to the Garden
                    will have attained the object [of life]. For the life of
                    this world is but goods and chattels of deception."
                    <li className="my-2">
                      <strong>Surah Ar-Ra’d (13:28)</strong>
                    </li>
                    الَّذِينَ آمَنُوا وَتَطْمَئِنُّ قُلُوبُهُم بِذِكْرِ اللَّهِ
                    أَلَا بِذِكْرِ اللَّهِ تَطْمَئِنُّ الْقُلُوبُ
                    <br />
                    "Those who believe, and whose hearts find satisfaction in
                    the remembrance of Allah: for without doubt, in the
                    remembrance of Allah do hearts find satisfaction."
                    <li className="my-2">
                      <strong>Surah Az-Zumar (39:42)</strong>
                    </li>
                    اللَّهُ يَتَوَفَّى الْأَنفُسَ ح
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  );
}
export default Faqs;
