import React, { useEffect, useState } from "react";
import AdminHeader from '../Layout/AdminHeader';
import Sidebar from '../Layout/Sidebar';
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../../Global/FileRoutes";

function AdminChangePassword() {

  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");
  const navigate = useNavigate();
  let parseData = {}
  if(userData)
  {
    parseData = JSON.parse(userData);
  }
  
  
  const [showPassword, setShowPassword] = useState(false);
  const [formData,setFormData] = useState({
    currentPassword:"",
    newPassword:"",
    confirmPassword:""
  })

  useEffect(() => {
    if(!token && parseData?.user_role !== "AD")
    {
      navigate(fileRoutes.adminLogin);
    }

    
  },[]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChange = (event) => {
    const {name,value} = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:value
    }))
  }


  const validations = () => {
    if(!formData.currentPassword)
    {
      Swal.fire({
        icon:'error',
        text:'Please Enter your current Password',
        title:"Error"
      })
      return false;
    }
    if(!formData.newPassword)
    {
      Swal.fire({
        icon:'error',
        text:'Please Enter your new Password',
        title:"Error"
      })
      return false;
    }
    if(!formData.confirmPassword)
    {
      Swal.fire({
        icon:'error',
        text:'Please Enter your confirm Password',
        title:"Error"
      })
      return false;
    }
    if(formData.newPassword.trim() !== formData.confirmPassword.trim())
    {
      Swal.fire({
        icon:'error',
        text:'Your New Password and confirm Password does not matched.',
        title:"Error"
      })
      return false;
    }

    return true;
  }

  const changePassword = () => {
    const valid = validations();
    if(valid)
    {
    let payload = {
      email:parseData.user_email,
      old_password:formData.currentPassword,
      new_password:formData.newPassword
    };

    axios.post("https://demise.ng/api/demise/admin/admin_reset_password.php",payload).then((response) => {
      if(response.data.status === "success")
      {
        Swal.fire({
          icon:"success",
          title:"Password Changed!",
          text:"Password Changed Successfully"
        })
      }
      else {
        Swal.fire({
          icon:"error",
          title:"Error",
          text:response.data.message
        })
      }
    })
  }
  }



  return (
    <div className='row bg-white'>
      <div className="col-xl-3 col-lg-4 col-md-4 pe-0">
        <Sidebar />
      </div>
      <div className="col-xl-9 col-lg-8 col-md-8 ps-0">
        <AdminHeader />
        <main className="d-flex align-items-center justify-content-center" style={{minHeight:"94vh"}}>
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-xl-7 col-lg-8">
                <div className="bg-white rounded-4 p-md-5 p-4">
                  <h1 className="fw-medium fs-4 text-center mb-4 py-3 rounded-5  mx-xl-5" style={{ backgroundColor: "var(--secondary-color)" }}>
                    Change Password
                  </h1>
                  <form className="row g-4 mt-3">
                    <div className="col-12">
                      <label class="form-label">Current Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control admin"
                          placeholder="Current Password"
                          value={formData.currentPassword}
                          name="currentPassword"
                          onChange={onChange}
                        />
                        <span
                          className="eyeIcon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i class="fa-solid fa-eye"></i>
                          ) : (
                            <i class="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <label class="form-label">New Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control admin"
                          placeholder="New Password"
                          value={formData.newPassword}
                          name="newPassword"
                          onChange={onChange}
                        />
                        <span
                          className="eyeIcon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i class="fa-solid fa-eye"></i>
                          ) : (
                            <i class="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <label class="form-label">Confirm Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control admin"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          name="confirmPassword"
                          onChange={onChange}
                        />
                        <span
                          className="eyeIcon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i class="fa-solid fa-eye"></i>
                          ) : (
                            <i class="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <button type="button" class="commonButton py-3 px-4 w-100 mt-5 fs-5" onClick={changePassword}>
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
export default AdminChangePassword;
