export const globalData = {
  creator: "creater",
};

export const convertDate = (dateString) => {
  if(dateString)
  {
  const date = new Date(dateString);

  // Format the date using the toLocaleDateString() method with options
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
  }
  else {
    return dateString;
  }
};

export const normalDate = (dateString) =>  {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Get year, month (0-indexed), and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for single-digit months
  const day = String(date.getDate()).padStart(2, '0');  // Add leading zero for single-digit days

  // Format the date in YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}
export const states = ["Abia",
  "Adamawa",
  "Federal Capital Territory",
  "Akwa",
  "Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross",
  "River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nassarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara"
];


export const categories = [
  "Florists",
  "Ambulance/Hearse Hire",
  "Caskets",
  "Funeral Video & Streaming",
  "Grave Maintenance",
  "Grave Markers, Plaques & Ornaments",
  "Headstones & Monuments",
  "Hotels",
  "Memorial Cards & Keyrings",
  "Funeral programmes and souvenirs",
  "Funeral directors",
  "Life Insurance",
]


export const introduction = [
"Transition to Glory",
"In Loving Memory",
"Painful Exit",
"Gone Too Soon",
"The Death of an Icon",
"Passing on to Glory",
"A Celebration of Life",
"Saying Goodbye",
"Farewell to a Beloved Soul",
"Heaven rejoices",
"Honouring a Legacy",
"Paying Respects",
"Inna Lillahi wa Inna Ilaihi Raji'un (إِنَّا لِلَّٰهِ وَإِنَّا إِلَيْهِ رَاجِعُونَ)",
"Janazah Announcement",
"A Service of Salat al-Janazah",
"Returned to Allah's Embrace",
"Honouring A Life in Submission to Allah",
"A Tribute",
"Forever in Our Hearts",

"Anniversaries and Remembrances",

"In Loving Memory",
"Remembering A Life Well Lived",
"Forever in Our Hearts",
"A Tribute to a Beautiful Life",
"A Cherished Soul Remembered",
"Gone But Never Forgotten",
"In Remembrance",
"A Journey Remembered",
"A Heartfelt Remembrance",
"An Unforgettable Legacy",
"In Sacred Memory",
"A Light That Continues to Shine",
"Returned to Allah's Embrace",
"In the Gardens of Jannah",
"Remembering A Servant of Allah",
"A Journey Back to the Merciful",
"In Loving Remembrance",
"Forever in the Mercy of Allah",
"In the Company of Angels",
"A Tribute to A Life of Devotion",
"Under Allah's Loving Care",
"A Soul Ascended to Allah",
"Keeping the Flame Alive",
"A Time to Remember",
"A Legacy of Love",
"Remembering A Light Among Believers",
"Journeyed Beyond the Veil",
"Wrapped in Divine Serenity",
"A Faithful Heart at Rest",
"In the Boundless Compassion of Allah"
]

export const deathNotice = [
  "Death Notice",
  "Memorial Notice"
]
