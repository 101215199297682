import React, { useEffect, useState } from "react";
import createDeathNotice from "../Assets/createDeathNotice.png";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import axios from "axios";
import {
  convertDate,
  deathNotice,
  introduction,
  states,
} from "../../Global/GlobalData";
import Swal from "sweetalert2";
import { fileRoutes } from "../../Global/FileRoutes";
import { useNavigate } from "react-router-dom";
function CreateDeathNotice() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [limitExceeds,setLimitExceeds] = useState(false);
  const [totalCharacters,setTotalCharacters] = useState(0);
  const initialState = {
    deathNotice: deathNotice[0],
    introduction: introduction[0],
    firstName: "",
    lastName: "",
    middleName: "",
    nickName: "",
    stateOfOrigin: states[0],
    lga: "",
    dob: "",
    dod: "",
    editorData: "",
    facebookLink: "",
    twitterLink: "",
    instagramLink: "",
    photos: [],
    profile: [],
    selectedProfile: [],
    selectedPhotos: [],
    funeralVideo: "",
    userFirstName: "",
    userLastName: "",
    email: "",
    mobileNum: "",
    relationship: "",
    familyPermission: false,
    releaseDeceased: false,
    linkedInLink: "",
  };
  const [formData, setFormData] = useState(initialState);
  console.log(formData);
  useEffect(() => {
    if (!token) {
      navigate(fileRoutes.userLogin);
    }
  }, []);

  const onChange = (event) => {
    const { name, type, value, files } = event.target;

    if (name === "photos") {
      // Check if the number of files exceeds the limit
      if (formData.selectedPhotos.length >= 2) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "You cannot upload more than 2 files",
        });
        return;
      }

      // Handle the file selection logic
      const selectedFiles = Array.from(files);
      const imageUrl = [{ name: URL.createObjectURL(event.target.files[0]) }];
      setFormData((prevState) => ({
        ...prevState,
        selectedPhotos: [...prevState.selectedPhotos, ...selectedFiles],
        photos: [...prevState.photos, ...imageUrl],
      }));
    } else if (name === "profile") {
      const selectedFiles = Array.from(files);
      const imageUrl = [{ name: URL.createObjectURL(event.target.files[0]) }];
      setFormData((prevState) => ({
        ...prevState,
        selectedProfile: [...selectedFiles],
        profile: [...imageUrl],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const submitBtn = () => {
    createDeathNoticeApi();
  };

  const cancelBtn = () => {
    setFormData(initialState);
    navigate(fileRoutes.userHome);
  };

  /* validations */
  const validations = () => {
    if (formData.profile.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Upload Profile Picture",
      });
      return false;
    }
    if (!formData.deathNotice) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Death Notice",
      });
      return false;
    }
    if (!formData.introduction) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Introduction",
      });
      return false;
    }
    if (!formData.firstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter First Name",
      });
      return false;
    }
    if (!formData.lastName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Last Name",
      });
      return false;
    }
    if (!formData.stateOfOrigin) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select A State",
      });
      return false;
    }
    if (!formData.dob) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Date of Birth",
      });
      return false;
    }
    if (!formData.dod) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Date of Death",
      });
      return false;
    }
    if (!editorState) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Description",
      });
      return false;
    }
    if (!formData.userFirstName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your First Name",
      });
      return false;
    }
    if (!formData.userLastName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Last Name",
      });
      return false;
    }
    if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email",
      });
      return false;
    }
    if (!formData.relationship) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your RelationShip With Deceased",
      });
      return false;
    }
    if (!formData.familyPermission) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Checked Family Permission Checkbox",
      });
      return false;
    }
    if (!formData.releaseDeceased) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Checked that Your Information are correct.",
      });
      return false;
    }
    if (formData.profile.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Add Your Profile Picture.",
      });
      return false;
    }
    return true;
  };

  const createDeathNoticeApi = () => {
    const valid = validations();
    if (valid) {
      const requestData = new FormData();
      let parseData = JSON.parse(userData);
      requestData.append("user_id", parseData.user_id);
      requestData.append("notice", formData.deathNotice);
      requestData.append("noticetype", formData.introduction);
      requestData.append("linkedinlink", formData.linkedInLink);
      // file[]:formData.
      requestData.append("firstname", formData.firstName);
      requestData.append("lastname", formData.lastName);
      requestData.append("middlename", formData.middleName);
      requestData.append("nickname", formData.nickName);
      requestData.append("stateoforigin", formData.stateOfOrigin);
      requestData.append("lga", formData.lga);
      requestData.append("dob", convertDate(formData.dob));
      requestData.append("dod", convertDate(formData.dod));
      requestData.append("instaurl", formData.instagramLink);
      requestData.append("fburl", formData.facebookLink);
      requestData.append("tweeturl", formData.twitterLink);
      requestData.append("videourl", formData.funeralVideo);
      requestData.append(
        "description",
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      );

      requestData.append("yourfirstname", formData.userFirstName);
      requestData.append("email", formData.email);
      requestData.append("mobilenumber", formData.mobileNum);
      requestData.append("relationship", formData.relationship);
      requestData.append("yourlastname", formData.userLastName);
      if (formData.selectedProfile.length > 0) {
        for (let i = 0; i < formData.selectedProfile.length; i++) {
          requestData.append("profile[]", formData.selectedProfile[i]);
        }
      } else {
        requestData.append("profile[]", []);
      }
      if (formData.selectedPhotos.length > 0) {
        for (let i = 0; i < formData.selectedPhotos.length; i++) {
          requestData.append("file[]", formData.selectedPhotos[i]);
        }
      } else {
        requestData.append("file[]", []);
      }

      axios
        .post(
          "https://demise.ng/api/demise/notice/create_notice.php",
          requestData
        )
        .then((response) => {
          if (response.data) {
            if (response.data.status === "error") {
              Swal.fire({
                icon: "error",
                title: "error",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Your Notice is Pending Approval!",
              }).then((confirm) => {
                if (confirm) {
                  setFormData(initialState);
                  navigate(fileRoutes.userHome);
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  console.log(formData.profile.length > 0 ? formData.profile[0].name : "");

  const removeImage = (imageIndex) => {
    const filterData = formData.photos.filter(
      (val, index) => index !== imageIndex
    );
    console.log(filterData);
    setFormData((prevState) => ({
      ...prevState,
      photos: [...filterData],
      selectedPhotos: [...filterData],
    }));
  };


  const changeEditor = (event) => {
    const contentState = event.getCurrentContent();
    const plainText = contentState.getPlainText();
    console.log("lenght",plainText.length);
    setTotalCharacters(plainText.length);
    if(plainText.length <= 1250)
    {
      setEditorState(event);
      setLimitExceeds(false);
    }
    else {
      setLimitExceeds(true);
    }
    
    
  }

  const onBlur = () => {
    if(totalCharacters > 1250)
    {
      Swal.fire({
        icon:"error",
        title:"Total Character Limit Exceeds!",
        text:"Your character limit have been exceeds you cannot add description more than 1,250 characters."
      })
    }
  }


  return (
    <>
      <UserHeader />
      <main className="homePage paddingY">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-12">
              <h1 className="fw-bold text-center">
                Create a Death or memorial Notice
              </h1>
              <div className="profile">
                <div className="img-box mt-4 mb-3 rounded-3">
                  <input type="file" onChange={onChange} name="profile" />
                  <button className="commonButton py-2 px-4 fw-semibold ">
                    Upload Image
                  </button>
                  <img
                    src={
                      formData.profile.length > 0
                        ? formData.profile[0].name
                        : ""
                    }
                    className="img-fluid rounded-3 mx-auto d-block "
                    width={310}
                    height={300}
                    alt="Profile"
                  />
                  <p className="notesText mt-1">
                    Please upload image with clear face of deceased person. *
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-5">
              <form className="row g-4">
                <div className="col-md-6">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="deathNotice"
                    value={formData.deathNotice}
                    onChange={onChange}
                  >
                    {deathNotice.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                  <label className="notesText">
                    Please if it's Death Notice or Memorial Notice *
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={formData.introduction}
                    onChange={onChange}
                    name="introduction"
                  >
                    {introduction.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                  <label className="notesText">
                    Please select introduction text to display at the top *
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name *"
                    value={formData.firstName}
                    onChange={onChange}
                    name="firstName"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name *"
                    name="lastName"
                    value={formData.lastName}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Middle Name"
                    name="middleName"
                    value={formData.middleName}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nick Name (if applicable)"
                    name="nickName"
                    value={formData.nickName}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    type="text"
                    placeholder="State of Origin"
                    name="stateOfOrigin"
                    onChange={onChange}
                    value={formData.stateOfOrigin}
                  >
                    {states.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                  <label className="notesText">
                    Please select state of deceased person *
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    class="form-control"
                    aria-label="Default select example"
                    name="lga"
                    placeholder="L.G.A"
                    value={formData.lga}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Date of Birth"
                      name="dob"
                      value={formData.dob}
                      onChange={onChange}
                    />
                    <label for="floatingTextarea">Date of birth *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Date of Death"
                      name="dod"
                      onChange={onChange}
                      value={formData.dod}
                    />
                    <label for="floatingTextarea">Date of death *</label>
                  </div>
                </div>
                <div className="col-12">
                  <Editor
                    editorState={editorState}
                    // onEditorStateChange={setEditorState}
                    onEditorStateChange={changeEditor}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onBlur={onBlur}
                  />
                  <p className="notesText">Character Limits {totalCharacters}/1,250</p>
                  <label className="notesText">
                    Please write few words about the deceased person *
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Facebook Link of the deceased (if applicable)"
                    value={formData.facebookLink}
                    name="facebookLink"
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Twitter Link of the deceased (if applicable)"
                    name="twitterLink"
                    value={formData.twitterLink}
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Instagram Link of the deceased (if applicable)"
                    name="instagramLink"
                    value={formData.instagramLink}
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="LinkedIn Link of the deceased (if applicable)"
                    name="linkedInLink"
                    value={formData.linkedInLink}
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Upload photos of the deceased (Max limit is 2)"
                    name="photos"
                    value=""
                    onChange={onChange}
                  />

                  <label className="notesText">
                    Upload photos of the deceased (Max limit is 2)
                  </label>
                  <br />
                  {formData.photos && formData.photos.length > 0 ? (
                    formData.photos.map((val, index) => (
                      <>
                        <div className="d-flex filesUploadAd">
                          <p>File Name: {val.name}</p>
                          <i
                            class="fa-solid fa-xmark mt-1 text-danger ms-3"
                            onClick={() => removeImage(index)}
                          ></i>
                        </div>
                        <img className="uploadedFiles" src={val.name} alt="uploaded file" />
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add a funeral video or streaming link (if applicable)"
                    name="funeralVideo"
                    value={formData.funeralVideo}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your First Name *"
                    value={formData.userFirstName}
                    name="userFirstName"
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your Last Name *"
                    onChange={onChange}
                    value={formData.userLastName}
                    name="userLastName"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email *"
                    name="email"
                    value={formData.email}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Mobile Number"
                    name="mobileNum"
                    value={formData.mobileNum}
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Your relationship to the deceased *"
                    value={formData.relationship}
                    name="relationship"
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      name="familyPermission"
                      checked={formData.familyPermission}
                      onChange={onChange}
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      I have obtained the family's permission to publish this
                      notice regarding the deceased
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      onChange={onChange}
                      name="releaseDeceased"
                      checked={formData.releaseDeceased}
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      I confirm that the information to be released regarding
                      the deceased is accurate
                    </label>
                  </div>
                </div>
                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center">
                  <button
                    type="button"
                    class="rounded-pill  py-3 px-4 w-100 btn btn-secondary fs-6 fw-semibold "
                    onClick={cancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="commonButton py-3 px-4 w-100 fs-6"
                    onClick={submitBtn}
                    disabled={limitExceeds}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
}
export default CreateDeathNotice;
