import React, { useEffect, useState } from "react";
import createDeathNotice from "../Assets/createDeathNotice.png";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import {
  convertDate,
  deathNotice,
  introduction,
  normalDate,
  states,
} from "../../Global/GlobalData";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { fileRoutes } from "../../Global/FileRoutes";

function EditDeathNotice() {
  const initialState = {
    noticeid: "",
    notice: "",
    noticetype: "",
    files: [],
    firstname: "",
    lastname: "",
    middlename: "",
    nickname: "",
    stateoforigin: "",
    lga: "",
    dob: "",
    dod: "",
    instaurl: "",
    fburl: "",
    tweeturl: "",
    videourl: "",
    email: "",
    mobilenumber: "",
    relationship: "",
    yourfirstname: "",
    yourlastname: "",
    profile: [],
    selectedProfile: [],
    selectedFiles: [],
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [formData, setFormData] = useState({
    noticeid: "",
    notice: "",
    noticetype: "",
    files: [],
    selectedFiles: [],
    selectedProfile: [],
    firstname: "",
    lastname: "",
    middlename: "",
    nickname: "",
    stateoforigin: "",
    lga: "",
    dob: "",
    dod: "",
    instaurl: "",
    fburl: "",
    tweeturl: "",
    videourl: "",
    email: "",
    mobilenumber: "",
    relationship: "",
    yourfirstname: "",
    yourlastname: "",
    profile: [],
  });
  const [limitExceeds,setLimitExceeds] = useState(false);
  const [totalCharacters,setTotalCharacters] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("location", location.state);
    if (location.state.data) {
      editNoticeAPI(location.state.data.noticeid);
    }
  }, []);

  const editNoticeAPI = (noticeId) => {
    let payload = {
      notice_id: noticeId,
    };
    axios
      .post("https://demise.ng/api/demise/notice/notice_detail.php", payload)
      .then((response) => {
        if (response.data.status === "success") {
          let imageData = [];
          let profileData = [];
          if (
            response.data.notice_datail.files &&
            response.data.notice_datail.files.length > 0
          ) {
            imageData = response.data.notice_datail.files.map((val) => {
              return  {name:val.imageurl}
            });
          }

          if (response.data.notice_datail.profileurl) {
            profileData = [{ name: response.data.notice_datail.profileurl }];
          }
          setFormData({
            user_id: response.data.notice_datail.user_id,
            notice: response.data.notice_datail.notice,
            noticetype: response.data.notice_datail.noticetype,
            firstname: response.data.notice_datail.firstname,
            lastname: response.data.notice_datail.lastname,
            middlename: response.data.notice_datail.middlename,
            nickname: response.data.notice_datail.nickname,
            stateoforigin: response.data.notice_datail.stateoforigin,
            lga: response.data.notice_datail.lga,
            dob: normalDate(response.data.notice_datail.dob),
            dod: normalDate(response.data.notice_datail.dod),
            fburl: response.data.notice_datail.fburl,
            instaurl: response.data.notice_datail.instaurl,
            tweeturl: response.data.notice_datail.tweeturl,
            imageurl: response.data.notice_datail.imageurl,
            videourl: response.data.notice_datail.videourl,
            yourfirstname: response.data.notice_datail.yourfirstname,
            yourlastname: response.data.notice_datail.yourlastname,
            email: response.data.notice_datail.email,
            mobilenumber: response.data.notice_datail.mobilenumber,
            relationship: response.data.notice_datail.relationship,
            id: response.data.notice_datail.id,
            noticeid: response.data.notice_datail.noticeid,
            profile: profileData,
            selectedProfile: profileData,
            isapproved: response.data.notice_datail.isapproved,
            createddate: response.data.notice_datail.createddate,
            files: imageData,
            selectedFiles: imageData,
          });

          let parseContent = JSON.parse(
            response.data.notice_datail.description
          );
          const data = convertFromRaw(parseContent);
          setTotalCharacters(data.getPlainText().length);
          const contentData = EditorState.createWithContent(data);
          setEditorState(contentData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChange = (event) => {
    const { name, type, value, files } = event.target;

    if (name === "file") {
      // Check if the number of files exceeds the limit
      if (formData.selectedFiles.length >= 2) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "You cannot upload more than 2 files",
        });
        return;
      }
    
      // Handle the file selection logic
      const selectedFiles = Array.from(files);
      const imageUrl = [{ name: URL.createObjectURL(event.target.files[0]) }];
      setFormData((prevState) => ({
        ...prevState,
        selectedFiles: [...prevState.selectedFiles, ...selectedFiles],
        files: [...prevState.files, ...imageUrl],
      }));
    } else if (name === "profile") {
      const selectedFiles = Array.from(files);
      const imageUrl = [{ name: URL.createObjectURL(event.target.files[0]) }];
      setFormData((prevState) => ({
        ...prevState,
        selectedProfile: [...selectedFiles],
        profile: [...imageUrl],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const submitBtn = () => {
    createDeathNoticeApi();
  };

  const cancelBtn = () => {
    setFormData(initialState);
    navigate(fileRoutes.userHome);
  };

  /* validations */
  const validations = () => {
    if (!formData.noticetype) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Death Notice",
      });
      return false;
    }
    if (!formData.notice) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Introduction",
      });
      return false;
    }
    if (!formData.firstname) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter First Name",
      });
      return false;
    }
    if (!formData.lastname) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Last Name",
      });
      return false;
    }
    if (!formData.stateoforigin) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select A State",
      });
      return false;
    }
    if (!formData.dob) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Date of Birth",
      });
      return false;
    }
    if (!formData.dod) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Select Date of Death",
      });
      return false;
    }
    if (!editorState) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Description",
      });
      return false;
    }
    if (!formData.yourfirstname) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your First Name",
      });
      return false;
    }
    if (!formData.yourlastname) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Last Name",
      });
      return false;
    }
    if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email",
      });
      return false;
    }
    if (!formData.relationship) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your RelationShip With Deceased",
      });
      return false;
    }
    if (formData.selectedProfile && formData.selectedProfile.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Upload Your Profile Picture",
      });
    }

    return true;
  };

  const createDeathNoticeApi = () => {
    const valid = validations();
    if (valid) {
      const requestData = new FormData();

      requestData.append("noticeid", formData.noticeid);
      requestData.append("notice", formData.notice);
      requestData.append("noticetype", formData.noticetype);
      // file[]:formData.
      requestData.append("firstname", formData.firstname);
      requestData.append("lastname", formData.lastname);
      requestData.append("middlename", formData.middlename);
      requestData.append("nickname", formData.nickname);
      requestData.append("stateoforigin", formData.stateoforigin);
      requestData.append("lga", formData.lga);
      requestData.append("dob", convertDate(formData.dob));
      requestData.append("dod", convertDate(formData.dod));
      requestData.append("instaurl", formData.instaurl);
      requestData.append("fburl", formData.fburl);
      requestData.append("tweeturl", formData.tweeturl);
      requestData.append("videourl", formData.videourl);
      requestData.append(
        "description",
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      );

      requestData.append("yourfirstname", formData.yourfirstname);
      requestData.append("email", formData.email);
      requestData.append("mobilenumber", formData.mobilenumber);
      requestData.append("relationship", formData.relationship);
      requestData.append("yourlastname", formData.yourlastname);
      if (formData.selectedProfile.length > 0) {
        for (let i = 0; i < formData.selectedProfile.length; i++) {
          requestData.append("profile[]", formData.selectedProfile[i]);
        }
      } else {
        requestData.append("profile[]", []);
      }
      const imageArray = [];
      if (formData.selectedFiles.length > 0) {
        for (let i = 0; i < formData.selectedFiles.length; i++) {
          if(formData.selectedFiles[i].type)
          {
          requestData.append("file[]", formData.selectedFiles[i]);
          }
          else {
            imageArray.push(formData.selectedFiles[i].name);
          }
        }
      } else {
        requestData.append("file[]", []);
      }

      if (imageArray.length > 0) {
        requestData.append("imageUrls", imageArray.join(","));
      } else {
        requestData.append("imageUrls", "");
      }

      axios
        .post(
          "https://demise.ng/api/demise/notice/edit_notice.php",
          requestData
        )
        .then((response) => {
          if (response.data) {
            if (response.data.status === "error") {
              Swal.fire({
                icon: "error",
                title: "error",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Death Notice Updated Successfully!",
              }).then((confirm) => {
                if (confirm) {
                  setFormData(initialState);
                  navigate(fileRoutes.userHome);
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const removeImage = (imageIndex) => {
    const filterData = formData.files.filter(
      (val, index) => index !== imageIndex
    );
    console.log(filterData);
    setFormData((prevState) => ({
      ...prevState,
      files: [...filterData],
      selectedFiles: [...filterData],
    }));
  };
  const changeEditor = (event) => {
    const contentState = event.getCurrentContent();
    const plainText = contentState.getPlainText();
    console.log("lenght",plainText.length);
    setTotalCharacters(plainText.length);
    if(plainText.length <= 1250)
    {
      setEditorState(event);
      setLimitExceeds(false);
    }
    else {
      setLimitExceeds(true);
    }
    
    
  }

  const onBlur = () => {
    if(totalCharacters > 1250)
    {
      Swal.fire({
        icon:"error",
        title:"Total Character Limit Exceeds!",
        text:"Your character limit have been exceeds you cannot add description more than 1,250 characters."
      })
    }
  }


  return (
    <>
      <UserHeader />
      <main className="homePage paddingY">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-12">
              <h1 className="fw-bold text-center">
                Edit a Death or memorial Notice
              </h1>
              <div className="profile">
                <div className="img-box mt-4 mb-3 rounded-3">
                  <input type="file" onChange={onChange} name="profile" />
                  <button className="commonButton py-2 px-4 fw-semibold ">
                    Upload Image
                  </button>
                  <img
                    src={
                      formData.profile && formData.profile.length > 0
                        ? formData.profile[0].name
                        : ""
                    }
                    className="img-fluid rounded-3 mx-auto d-block "
                    width={310}
                    height={300}
                    alt="Profile"
                  />
                  <p className="notesText mt-1">
                    Please upload image with clear face of deceased person. *
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-5">
              <form className="row g-4">
                <div className="col-md-6">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="notice"
                    value={formData.notice}
                    onChange={onChange}
                  >
                    <option value=""></option>
                    {deathNotice.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                  <label className="notesText">
                    Please if it's Death Notice or Memorial Notice *
                  </label>
                </div>
                <div className="col-md-6">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="noticetype"
                    value={formData.noticetype}
                    onChange={onChange}
                  >
                    <option value=""></option>
                    {introduction.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                  <label className="notesText">
                    Please select introduction text to display at the top *
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name *"
                    onChange={onChange}
                    name="firstname"
                    value={formData.firstname}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Last Name *"
                    onChange={onChange}
                    value={formData.lastname}
                    name="lastname"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Middle Name"
                    name="middlename"
                    value={formData.middlename}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nick Name "
                    name="nickname"
                    onChange={onChange}
                    value={formData.nickname}
                  />
                </div>
                <div className="col-md-6">
                  <select
                    className="form-select"
                    placeholder="State of Origin"
                    value={formData.stateoforigin}
                    name="stateoforigin"
                    onChange={onChange}
                  >
                    <option value=""></option>
                    {states.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                  <label className="notesText">
                    Please select state of deceased person *
                  </label>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="L.G.A"
                    class="form-control"
                    name="lga"
                    value={formData.lga}
                    onChange={onChange}
                    aria-label="Default select example"
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="date"
                      placeholder="State of Origin"
                      name="dob"
                      value={formData.dob}
                      onChange={onChange}
                    />
                    <label for="floatingTextarea">Date of birth *</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="date"
                      name="dod"
                      onChange={onChange}
                      value={formData.dod}
                      placeholder="State of Origin"
                    />
                    <label for="floatingTextarea">Date of death *</label>
                  </div>
                </div>
                <div className="col-12">
                  <Editor
                    editorState={editorState}
                    // onEditorStateChange={setEditorState}
                    onEditorStateChange={changeEditor}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onBlur={onBlur}
                  />
                     <p className="notesText">Character Limits {totalCharacters}/1,250</p>
                  <label className="notesText">
                    Please write few words about the deceased person *
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Facebook Link of the deceased (if applicable)"
                    name="fburl"
                    onChange={onChange}
                    value={formData.fburl}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Twitter Link of the deceased (if applicable)"
                    name="tweeturl"
                    value={formData.tweeturl}
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Instagram Link of the deceased (if applicable)"
                    value={formData.instaurl}
                    name="instaurl"
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Upload photos of the deceased (Max limit is 2)"
                    name="file"
                    onChange={onChange}
                    value=""
                  />
                </div>
                <label className="notesText">
                  Upload photos of the deceased (Max limit is 2)
                </label>
                {formData.files && formData.files.length > 0 ? (
                  formData.files.map((val, index) => (
                    <>
                      <div className="d-flex filesUploadAd">
                        <p>File Name: {val.name}</p>
                        <i
                          class="fa-solid fa-xmark mt-1 text-danger ms-3"
                          onClick={() => removeImage(index)}
                        ></i>
                      </div>
                      <img
                        className="uploadedFiles"
                        src={val.name}
                        alt="uploaded file"
                      />
                    </>
                  ))
                ) : (
                  <></>
                )}
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add a funeral video or streaming link (if applicable)"
                    value={formData.videourl}
                    name="videourl"
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your First Name *"
                    value={formData.yourfirstname}
                    onChange={onChange}
                    name="yourfirstname"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    name="yourlastname"
                    value={formData.yourlastname}
                    placeholder="Your Last Name *"
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email *"
                    name="email"
                    onChange={onChange}
                    value={formData.email}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    type="number"
                    value={formData.mobilenumber}
                    name="mobilenumber"
                    onChange={onChange}
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Your relationship to the deceased *"
                    value={formData.relationship}
                    name="relationship"
                    onChange={onChange}
                  />
                </div>
                {/* <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      I have obtained the family's permission to publish this
                      notice regarding the deceased
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      I confirm that the information to be released regarding
                      the deceased is accurate
                    </label>
                  </div>
                </div> */}
                <div class="d-grid gap-3 d-sm-flex justify-content-sm-center">
                  <button
                    type="button"
                    class="rounded-pill  py-3 px-4 w-100 btn btn-secondary fs-6 fw-semibold "
                    onClick={cancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="commonButton py-3 px-4 w-100 fs-6"
                    onClick={submitBtn}
                    disabled={limitExceeds}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
}
export default EditDeathNotice;
