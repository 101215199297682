import React, { useEffect, useState } from "react";
import logoCircle from "../Assets/logoCircle.png";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { EditorState, convertFromRaw  } from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
function ServicesDirectorySingle() {

  const [singleServiceData,setSingleServiceData] = useState({});
  const [decriptionContent,setDescriptionContent] = useState(<></>);
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    singleServiceDataAPI();
  },[]);


  const singleServiceDataAPI = () => {
    if(location.state.advertiser_id)
    {
      let payload = { 
        advertiser_id:location.state.advertiser_id
      }
      axios.post("https://demise.ng/api/demise/advertiser/advertiser_detail.php",payload).then((response) => {
        if(response.data.status === "success")
        {
          console.log('Response',response.data.advertiserprofile_datail);
          let parseContent = JSON.parse(
            response.data.advertiserprofile_datail.description
          );
          const data = convertFromRaw(parseContent);
          let html = stateToHTML(data);
          setDescriptionContent(html);
          setSingleServiceData(response.data.advertiserprofile_datail)
        }
      }).catch((error) => (
        console.log('Error',error)
      ))
    }
  };


  const gotoCtaLink = () => {
    navigate(singleServiceData.ctabtnlink);
  }


  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            { singleServiceData && Object.keys(singleServiceData).length > 0 ? (
            <div className="row">
              <div className="col-12">
                <div className="bg-white rounded-4 p-lg-5 p-4">
                  <div class="card border-0">
                    <div class="row g-0 align-items-center">
                      <div class="col-md-3">
                        <img
                          src={singleServiceData.profileurl}
                          class="img-fluid w-100 rounded-circle object-fit-cover advertiserProfilePic"
                          alt="..."
                        />
                      </div>
                      <div class="col-md-9">
                        <div class="card-body">
                          <h3 class="fw-bold mb-2">{singleServiceData.companyname}</h3>
                          <button
                            type="button"
                            class="commonButton py-3 px-5 mt-3"
                            onClick={gotoCtaLink}
                          >
                          {singleServiceData.ctabtntxt}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="py-5">
                    <div dangerouslySetInnerHTML={{__html:decriptionContent}} />
                    </div>
                    <div class="d-flex justify-content-start align-items-center advertiserImageGallery">
                    {
                      singleServiceData.files && singleServiceData.files.map((val) => (
                        <img alt="gallery" src={val.imageurl}></img>
                      ))
                    }
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-4 p-lg-5 p-4 mt-lg-5 mt-3 ">
                  <div className="row g-lg-5 g-3 ">
                    <div className="col-lg-6">
                      <h3 className="fw-semibold mb-3">Contact Details</h3>
                      { singleServiceData.address !== "" ? (
                        <>
                      <p className="text-black-50">Location</p>
                      <p className="text-black-50 mb-0 ">
                      {singleServiceData.address}
                      </p>
                      </>
                      ) : <></>}
                    </div>
                    <div className="col-lg-6">
                      {
                        singleServiceData.phone !== "" ? (
                          <div className="d-flex gap-3 align-items-center">
                          <i class="fa-solid fa-phone fs-5 text-black"></i>
                          <span>
                            <a href={`tel:${singleServiceData.phone}`}>
                            {singleServiceData.phone}
                            </a>
                          </span>
                        </div>
                        ) : <></>
                      }
                      <div className="d-flex gap-3 align-items-center mt-4">
                        <i class="fa-solid fa-envelope fs-5 text-black"></i>
                        <span>
                          <a href={`mailto:${singleServiceData.useremail}`}>{singleServiceData.useremail}</a>
                          </span>
                      </div>
                      <div className="d-flex gap-3 align-items-center mt-4">
                        <i class="fa-solid fa-globe fs-5 text-black" hidden={singleServiceData.website === ""}></i>
                        <span hidden={singleServiceData.website === ""}>{singleServiceData.website}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ) : <></>
}
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  );
}
export default ServicesDirectorySingle;
