import React from "react";
import { fileRoutes } from "../../../Global/FileRoutes";

function AdminHeader() {

    const logout = () => {
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
    }


  return (
    <header class="navbar bg-white flex-md-nowrap px-0 shadow-sm" data-bs-theme="dark">
      <div className="d-flex justify-content-between gap-3 w-100 px-4 py-2">
        <h5><span className="fw-semibold">Hello,</span> Admin</h5>
        <div className="d-flex align-items-center gap-3">

          <a class="fs-6 text-black pe-md-2" href={fileRoutes.adminLogin} onClick={logout}>Logout</a>
          <button class="nav-link text-white d-block d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa-solid fa-bars text-dark"></i>
          </button>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;
