import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fileRoutes } from "../../../../../Global/FileRoutes";
const DeathNoticeCard = ({ serviceCard }) => {
  
  const navigate = useNavigate();

const singleNotice = (data) => {
  console.log('data',data);
  navigate(fileRoutes.singleDeathNotice,{state:{value:data}});
}

  return (
    <>
      {serviceCard && serviceCard.map((card, id) => (
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex ">
          <div className="card w-100 " key={id}>
            <div className="card-body text-center">
              <h5 className="card-title mt-5 text-dark pb-2">{card.firstname} {card.middlename} {card.lastname}</h5>
            </div>
            <div className="position-absolute profile-pic-wrapper">
              <div className="bg-white p-4 rounded-circle deathNoticePicture" style={{backgroundImage: `url('https://demise.ng/api/demise/notice/${card.profileurl}')`}}>
              </div>
            </div>

          <div>
          <h6  className="text-center mb-3">{card.stateoforigin}</h6>

          </div>

            <div className="card-footer text-center py-3">
              <button className="noticeArchiveBtn" onClick={() => singleNotice(card)}>
               View Notice
               </button>
            </div>
          </div>
        </div>

      ))}
    </>
  );
};

export default DeathNoticeCard;
