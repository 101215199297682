import React, { useEffect, useState } from "react";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";
import axios from "axios";
import Swal from "sweetalert2";

const UserDeathNotices = () => {
  const [gridData, setGridData] = useState([]);

  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");

  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate(fileRoutes.userLogin);
    } else {
      getAllDeathNotice();
    }
  }, []);

  const getAllDeathNotice = () => {
    let parseData = JSON.parse(userData);
    let payload = {
      userid: parseData ? parseData.user_id : 0,
    };
    axios
      .post("https://demise.ng/api/demise/notice/all_notice.php", payload)
      .then((response) => {
        if (response.status === 200) {
          console.log("response", response.data);
          if (response.data.status !== "error") {
            setGridData(response.data.data);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const goToCreateNotice = () => {
    navigate(fileRoutes.createDeathNotice);
  };

  const editData = (data) => {
    navigate(fileRoutes.editNoticeArchive,{state:{data:data}});
  }

  const deleteModal = (data) => {
 
Swal.fire({
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!"
}).then((result) => {
  if (result.isConfirmed) {
   deleteApi(data.noticeid);
  }
});
  }

  const deleteApi = (data) => {
    let payload = {
      notice_id:data
    }
    axios.post("https://demise.ng/api/demise/notice/delete_notice.php",payload).then((response) => {
      if(response.data.status === "sucess")
      {
        console.log("response",response);
        Swal.fire({
          title: "Notice Deleted!",
          text: response.data.message,
          icon: "success"
        });
        getAllDeathNotice();
      }
    }).catch((error) => {
      console.log("error",error);
    })
  }


  return (
    <>
      <UserHeader />
      <main className="paddingY">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="fw-bold text-center">Welcome User, Your Notices</h1>
              <div className="table-responsive">
                <table class="table mt-lg-5 mt-4">
                  <thead>
                    <tr>
                      <td scope="col">Name</td>
                      <td scope="col">Relationship </td>
                      <td scope="col">State</td>
                      <td scope="col">Date of Demise</td>
                      <td scope="col">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {gridData &&
                      gridData.map((val) => (
                        <tr>
                          <td>
                            {val.firstname} {val.middlename} {val.lastname}
                          </td>
                          <td>{val.relationship}</td>
                          <td>{val.stateoforigin}</td>
                          <td>{val.dod}</td>
                          <td>
                            <div className="d-flex gap-2 align-items-center justify-content-center ">
                              <button className="commonButton px-4 py-2 fw-medium" onClick={() => editData(val)}>
                                <i class="fa-solid fa-pencil pe-1 small"></i>{" "}
                                Edit
                              </button>
                              <button className="commonButton px-4 py-2 fw-medium" onClick={() => deleteModal(val)}>
                                <i class="fa-solid fa-trash-can pe-1 small"></i>{" "}
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center mt-4">
            <button
              onClick={goToCreateNotice}
              className="commonButton px-4 py-2 fw-medium w-25"
            >
              Create a Notice
            </button>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
};

export default UserDeathNotices;
