import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";
import { states } from "../../Global/GlobalData";

function UserFooter() {
  const navigate = useNavigate();
  const goToDeathNotice = (val) => {
      navigate(fileRoutes.deathNoticeArchive,{state:{data:val}});
  }


  return (
    <footer className="py-4">
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 gap-md-5 gap-3 justify-content-center ">
          <div className="col-md-2 mb-3">
            <h5 className="text-white border-3 border-bottom pb-2 fw-normal">
              Demise.ng
            </h5>
            <ul className="nav flex-column mt-4">
              <li className="nav-item mb-3">
                <a href="/" className="nav-link p-0 ">
                  Home
                </a>
              </li>
              <li className="nav-item mb-3">
                <a
                  href={fileRoutes.deathNoticeArchive}
                  className="nav-link p-0"
                >
                  Death Notices
                </a>
              </li>
              <li className="nav-item mb-3">
                <a
                  href={fileRoutes.serviceDirectorySearch}
                  className="nav-link p-0"
                >
                  Service Directory
                </a>
              </li>
              <li className="nav-item mb-3">
                <a href={fileRoutes.AboutUs} className="nav-link p-0">
                  About Us
                </a>
              </li>
              <li className="nav-item mb-3">
                <a href={fileRoutes.faqs} className="nav-link p-0">
                  Practical Information
                </a>
              </li>
              <li className="nav-item mb-3">
                <a href={fileRoutes.contactUs} className="nav-link p-0">
                  Contact Us
                </a>
              </li>
              <li className="nav-item mb-3">
                <a href="https://medium.com/@demisedotng" target="_blank" className="nav-link p-0">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-2 mb-3">
            <h5 className="text-white border-3 border-bottom pb-2 fw-normal">
              Service Provider
            </h5>
            <ul className="nav flex-column mt-4">
              <li className="nav-item mb-3">
                <a
                  href={`${fileRoutes.advertiserLogin}`}
                  className="nav-link p-0"
                >
                  Login
                </a>
              </li>
              <li className="nav-item mb-3">
                <a
                  href={`${fileRoutes.advertiserRegister}`}
                  className="nav-link p-0"
                >
                  Register
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4 mb-3">
            <h5 className="text-white border-3 border-bottom pb-2 fw-normal">
              Death Notices
            </h5>
            <div className="row">
              <div className="col">
                <ul className="nav footerStates mt-4">
                  {states.map((val) => (
                    <li className="nav-item mb-3" onClick={() => goToDeathNotice(val)}>
                      <a
                        className="nav-link p-0"
                      >
                        {val}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ul class="nav justify-content-center border-bottom border-top mb-3 gap-md-3 gap-1">
          <li class="nav-item">
            <a href={fileRoutes.PrivacyPolicy} class="nav-link px-2">
              Privacy Policy
            </a>
          </li>
          <li class="nav-item">
            <a href={fileRoutes.TermsOfUse} class="nav-link px-2">
              Terms of Use
            </a>
          </li>
          <li class="nav-item">
            <a href={fileRoutes.CookiePolicy} class="nav-link px-2">
              Cookie Policy
            </a>
          </li>
        </ul>

        <p class="text-center text-white mb-0 fw-light ">
          © Copyright Demise.ng All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}
export default UserFooter;
