import React from "react";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";

function PrivacyPolicy() {
  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12 contentText">
                <h1 class="display-5 fw-semibold mb-5">
                  DEMISE.NG PRIVACY POLICY
                </h1>
                <div className="mb-5 mt-3">
                  <p>
                    Demise Affairs Ltd. trading as Demise.ng, ("We" or “Demise.ng”) are committed to protecting and respecting your privacy. This statement (together with our   terms and conditions   and   cookie policy    sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.
                  </p>
                  <p>Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By using our website   www.demise.ng    and its subdomains and mobile versions (our “Site”) you indicate your acceptance of this Privacy Statement. If you do not agree to this Privacy Statement, do not use or access the Site.</p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    1.       INFORMATION WE COLLECT
                  </h5>
                  <p>
                    Demise Affairs Ltd. is a Data Controller, where we provide employment to our staff.
                  </p>
                  <p>
                    Demise Affairs Ltd. is a Data Processor where we offer the use of our site to clients. For example, where individuals post a death notice on our site, the individual is the Data Controller, Demise Affairs Ltd. is the Data Processor.
                  </p>
                  <p>
                    This information is a general description of processing activities and information.  Please contact us should you wish to receive specific information on processing activities that relate to your personal data. The Personal Data records held by Demise Affairs Ltd. may include:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Staff records</li>
                  </ul>
                  <p>a) Categories of Staff Data</p>
                  <p>
                    As well as existing members of staff (and former members of staff), these records may also relate to applicants applying for positions within the company, trainee staff and staff under probation. These staff records may include:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Name, address and contact details. Name and contact details of next-of-kin in case of emergency.</li>
                    <li className="mb-3">Banking details.</li>
                    <li className="mb-3">Original records of application and appointment to promotion posts.</li>
                    <li className="mb-3">Details of approved absences (career breaks, parental leave, study leave, etc.).</li>
                    <li className="mb-3">Details of work record (qualifications, courses attended etc.).</li>
                    <li className="mb-3">Details of any accidents/injuries sustained on company property or in connection with the staff member carrying out their duties.</li>
                  </ul>
                  <p>b) Purposes of processing</p>
                  <p>
                    Staff records are kept for the purposes of:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">the management and administration of Demise Affairs Ltd. business (now and in the future)</li>
                    <li className="mb-3">to facilitate the payment of staff, and calculate other benefits/entitlements (including reckonable service for the purpose of calculation of pension payments, entitlements and/or redundancy payments where relevant)</li>
                    <li className="mb-3">human resources management</li>
                    <li className="mb-3">recording promotions made (documentation relating to promotions applied for) and changes in responsibilities, etc.</li>
                    <li className="mb-3">to enable Demise Affairs Ltd. to comply with its obligations as an employer, including the preservation of a safe, efficient working environment (including complying with its responsibilities under Safety, Health and Welfare.</li>
                    <li className="mb-3">to enable Demise Affairs Ltd. to comply with requirements set down by the Nigerian government.</li>
                    <li className="mb-3">and for compliance with legislation relevant to Demise Affairs Ltd.</li>
                  </ul>
                  <p>c) Lawful Bases for Processing</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Statutory Obligations (e.g. payment of payroll taxes etc.)</li>
                    <li className="mb-3">Necessary for the execution of contractual obligations</li>
                    <li className="mb-3">Necessary for obligations under employment, taxation and social security law</li>
                    <li className="mb-3">Legitimate interests – It is in the legitimate interest of Demise Affairs Ltd. to efficiently manage staff and ensure compliance with duties of care and other obligations.</li>
                  </ul>
                  <p>d) Location and Security Procedures</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Manual records are kept (a) in a secure, locked filing cabinet in a locked administration office (b) in a locked room only accessible to personnel who are authorised to use the data. Employees are required to maintain the confidentiality of any data to which they have access.</li>
                    <li className="mb-3">Digital records are stored on password-protected computer with adequate encryption and firewall software in a locked office.</li>
                    <li className="mb-3">Clients, Creditors, Job Applicants.</li>
                  </ul>
                  <p>a) Categories of Personal Data</p>
                  <p>
                    Demise Affairs Ltd. may hold some or all of the following information about the above data subjects (some of whom may be self-employed individuals):
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Name</li>
                    <li className="mb-3">Address</li>
                    <li className="mb-3">Contact details</li>
                    <li className="mb-3">VAT Registration</li>
                    <li className="mb-3">Tax details</li>
                    <li className="mb-3">Bank details and</li>
                    <li className="mb-3">Amount paid</li>
                    <li className="mb-3">Work history</li>
                    <li className="mb-3">Qualifications</li>
                    <li className="mb-3">Third-Party employment references</li>
                  </ul>
                  <p>b) Purposes of processing</p>
                  <p>
                    The purposes for processing client records are:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">To enable Demise Affairs Ltd. to conduct its legitimate business operations and to market its products and / or services.</li>
                    <p>The purposes for processing creditor records are:</p>
                    <li className="mb-3">This information is required for routine management and administration of Demise Affairs Ltd.’s financial affairs, including the payment of invoices, the compiling of annual financial accounts and complying with audits and investigations by the Revenue Commissioners.</li>
                    <p>The purposes for processing Job Applicant records are:</p>
                    <li className="mb-3">To facilitate the recruitment and hiring of staff.</li>
                  </ul>
                  <p>c) Lawful Bases for Processing</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Statutory Obligations (e.g. payment of VAT etc.)</li>
                    <li className="mb-3">Necessary for the execution of contractual obligations</li>
                  </ul>
                  <p>d) Location and Security Procedures</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Manual records are kept (a) in a secure, locked filing cabinet in a locked administration office (b) in a locked room only accessible to personnel who are authorised to use the data. Employees are required to maintain the confidentiality of any data to which they have access.</li>
                    <li className="mb-3">Digital records are stored on password-protected computer with adequate encryption and firewall software in a locked office.</li>
                  </ul>
                  <p>Information we collect about you via this website. </p>
                  <p>With regard to each of your visits to our Site we may automatically collect the following information:</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; and</li>
                    <li className="mb-3">information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our Site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page.</li>
                  </ul>
                  <p>This is statistical data about our users' browsing actions and patterns, and we cannot identify any individual.</p>
                  <p>In cases where registered Members who login to the Members’ Area on demise.ng using their Facebook login details, Facebook becomes a Joint Controller of the Joint Processing of their data. Information required by Article 13(1)(a) and (b) GDPR can be found in Facebook Data Policy at https://www.facebook.com/about/privacy.</p>
                  <p>Information we receive from other sources.</p>
                  <p>We work closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers) and may receive information about you from them.</p>
                  <p>The General Data Protection Regulation</p>
                  <p>Demise Affairs Ltd. is a Data Controller where we provide employment to our staff.</p>
                  <p>Demise Affairs Ltd. is a Data Processor where we offer the use of our site to clients. For example, where an individual posts a death notice on our site, the individual is the Data Controller, Demise Affairs Ltd. is the Data Processor.</p>
                  <p>The Personal Data records held by Demise Affairs Ltd. may include:</p>
                  <p>Personal Data</p>
                  <p>We will collect personal data about you in accordance with the purposes outlined in this document.  This will be basic or regular personal data used to facilitate a consultant/client type relationship usually your name and email address and from time to time billing information.  If you are a sole trader or partnership, we would consider your address to be personal data.</p>
                  <p>Special Category Personal Data</p>
                  <p>Certain types of Personal Data are classed as ‘Special Category’ data. The special categories of data are:</p>
                  <ul style={{ listStyleType: "num", paddingLeft: "1rem" }}>
                    <li className="mb-3">Personal data revealing racial or ethnic origin.</li>
                    <li className="mb-3">Political opinions.</li>
                    <li className="mb-3">Religious or philosophical beliefs.</li>
                    <li className="mb-3">Trade union membership.ails</li>
                    <li className="mb-3">Genetic data and biometric data processed for the purpose of uniquely identifying a natural person.</li>
                    <li className="mb-3">Data concerning health.</li>
                    <li className="mb-3">Data concerning a natural person’s sex life or sexual orientation.</li>
                  </ul>
                  <p>We will not collect special category data from you.</p>
                  <p>Criminal Conviction Data</p>
                  <p>We will not collect criminal conviction data from you.</p>
                  <p>Children’s Personal Data</p>
                  <p>If you would like to make use of our services and you are not yet 18 years old, we require that an adult is present when you register, if registration is required. Where consent is required to process your Personal Data as a child, we will obtain that consent from the adult who is authorised to give the consent on your behalf.  You must be at least 18 years old to create an account and engage in activities and transactions on our digital and social media. By creating an account or engaging in activities or transactions on our digital and social media, you affirm that you are at least 18 years old and are fully able to enter into and comply with our regular Terms of Use and this Privacy Policy. If we are notified or learn that a child has submitted Personal Data to us through our digital or social media without the correct permissions or consents, we will delete such Personal Data.</p>
                </div>

                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    THE COOKIES WE USE
                  </h5>
                  <p>
                    We use the following cookies:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">Strictly necessary cookies</li>
                    <p>These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</p>
                    <li className="mb-3">Preference cookies</li>
                    <p>Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</p>
                    <li className="mb-3">Statistics cookies</li>
                    <p>Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously</p>
                    <li className="mb-3">Marketing   cookies</li>
                    <p>Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</p>
                  </ul>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    2.       USES MADE OF THE INFORMATION
                  </h5>
                  <p>
                    We use information collected in the following ways:
                  </p>
                  <p>Where you provide information to us we may use this:</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">To carry out our obligations arising from any contracts entered into between you and us.</li>
                    <li className="mb-3">To provide you with information, products or services that you request from us or to provide you with information about other services we offer that are similar to those that you have already purchased or enquired about.</li>
                    <li className="mb-3">To notify you about changes to our service.</li>
                    <li className="mb-3">To ensure that content from our Site is presented in the most effective manner for you and for your computer.</li>
                    <li className="mb-3">We may also provide third parties with aggregate information about our users that does not identify them as individuals.</li>
                  </ul>
                  <p>Where we collect information about you we may use this:</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">To administer our Site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes.</li>
                    <li className="mb-3">To improve our Site to ensure that content is presented in the most effective manner for you and for your computer.</li>
                    <li className="mb-3">To allow you to participate in interactive features of our service, when you choose to do so.</li>
                    <li className="mb-3">As part of our efforts to keep our Site safe and secure.</li>
                    <li className="mb-3">To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you.</li>
                    <li className="mb-3">To make suggestions and recommendations to you and other users of our Site about goods or services that may interest you or them.</li>
                  </ul>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    3.       DISCLOSURE OF YOUR INFORMATION
                  </h5>
                  <p>
                    Your personal information may be disclosed in the following ways:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries. The reason for this disclosure includes, but is not limited to, enabling one of our related companies to perform certain services on our behalf.</li>
                    <li className="mb-3">We may disclose your personal information to business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you. </li>
                    <li className="mb-3">In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</li>
                    <li className="mb-3">If demise.ng or a substantial part of its assets are acquired by a third party personal data held by us about our customers will be one of the transferred assets.</li>
                    <li className="mb-3">Where advertising is displayed on our Site we may disclose data to advertisers and advertising networks that require the data to select and serve relevant adverts to you and others.  We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 500 people from a specific state have clicked on their advertisement on any given day). We may also use such aggregate information to help advertisers reach the kind of audience they want to target (for example, visitors from a particular state). We may make use of the personal data we have collected from you to enable us to comply with our advertisers' wishes by displaying their advertisement to that target audience.</li>
                    <li className="mb-3">Analytics and search engine providers that assist us in the improvement and optimisation of our Site.</li>
                    <li className="mb-3">If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use, or to protect the rights, property, or safety of demise.ng, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection.</li>
                  </ul>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    4.       WHERE WE STORE YOUR PERSONAL DATA
                  </h5>
                  <p>
                    The data that we collect from you may be transferred to, and stored at, a destination outside Nigeria. It may also be processed by staff operating outside Nigeria who work for us or for one of our suppliers. Such staff may be engaged in, among other things, the fulfilment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Statement.
                  </p>
                  <p>All information you provide to us is stored on our secure servers and we take the security of the data that we store seriously. However, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site and any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    5.       USER RIGHTS
                  </h5>
                  <p>
                    You have the right to access information held about you. Your right of access can be exercised by sending an email to info@demise.ng.
                  </p>
                  <p>Users have the right to ask us not to process personal data for marketing purposes. You can exercise your right to prevent such processing by ticking certain boxes on the forms we use to collect user data, unsubscribing via the link on any marketing email you receive from us or by contacting us at info@demise.ng.</p>
                  <p>For processing activities for which we rely on consent as a basis for processing your data, you have the right to withdraw your consent at any time.</p>
                  <p>For processing activities which are based on a statutory or contractual requirement, you may request your data not be processed for that purpose. However, this is not an absolute right and may be over-ridden by our statutory obligations. In other cases, requesting that data should not be processed for a particular reason may prevent us from executing a contract or delivering a service to you.</p>
                  <p>You have the right to request:</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">•	A copy of data we hold about you. (Right of Access)</li>
                    <li className="mb-3">•	That any error in data we hold about you is corrected. (Right of Rectification)</li>
                    <li className="mb-3">•	That data we hold about you be erased, unless we have an overriding interest or legal obligation to retain it. (Right of Erasure)</li>
                    <li className="mb-3">•	That we refrain from processing data for a specific purpose. (Right to Restrict processing)</li>
                  </ul>
                  <p>
                    To exercise your user rights noted above, please contact us by email at info@demise.ng
                  </p>
                  <p>
                    Our Site contains links to and from the websites of our partner networks, advertisers and affiliates.  If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.  Please check these policies before you submit any personal data to these websites.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    6.       YOUR RIGHT TO COMPLAIN
                  </h5>
                  <p>
                    You have the right to lodge an official complaint regarding our use of your data.
                  </p>
                  <p>Please tell us first, so we have a chance to address your concerns. </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    7.       CHANGES TO OUR PRIVACY STATEMENT
                  </h5>
                  <p>
                    Any changes we may make to our privacy statement in the future will be posted on this page. Please check back frequently to see any updates or changes to this statement.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    8.       CONTACT
                  </h5>
                  <p>
                    Questions, comments and requests regarding this privacy statement are welcomed and should be addressed to  info@demise.ng.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  );
}
export default PrivacyPolicy;
