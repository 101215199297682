import React, { useState } from "react";
import deathList from "../../../../Assets/deathList.png";
import ReactTooltip from "react-tooltip";

import MapChart from "../../../mapChart";
import { fileRoutes } from "../../../../../Global/FileRoutes";
import { useNavigate } from "react-router-dom";

const Index = ({ noticeData, stateData }) => {
  const [tooltipContent, setTooltipContent] = useState(null);
  const navigate = useNavigate();

  const goToDeathNotice = (value) => {
    console.log("Value", value);
    navigate(fileRoutes.singleDeathNotice, { state: { value: value } });
  };

  const goToAllDeathNotice = () => {
    navigate(fileRoutes.deathNoticeArchive);
  };

  return (
    <section className="listing-sec paddingY">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xl-8 bg-white mb-lg-0 mb-4 mapColumn">
          <h5 className="fw-bold text-center pt-4">Death Notices by States</h5>
            <MapChart
              setTooltipContent={setTooltipContent}
              stateData={stateData}
            />
            <ReactTooltip className="custom-tooltip shadow" arrowColor="white">
              {tooltipContent && (
                <div>
                  <p className="fw-semibold  mb-2">{tooltipContent.name}</p>
                  <p className="mb-2">
                    Notices this Week:{" "}
                    <span className="bg-info py-1 px-2 rounded-1 text-white">
                      {tooltipContent.projects}
                    </span>
                  </p>
                  <p className="text-warning mb-2">
                    Service Directory:{" "}
                    <span className="bg-warning py-1 px-2 rounded-1 text-white">
                      {" "}
                      {tooltipContent.serviceDirectory}{" "}
                    </span>
                  </p>
                </div>
              )}
            </ReactTooltip>
          </div>
          <div className="col-lg-5 col-xl-4">
            <div class="card pb-4 border-0 ">
              <div class="card-body">
                <h5 className="fw-bold">Featured Death Notices</h5>
              </div>
              <ul class="list-group list-group-flush p-sm-4 p-3 border-0 d-flex flex-column gap-3">
                {noticeData && noticeData.length > 0 ? (
                  noticeData.map((val,index) => 
                    {
                      if(index < 5)
                      { return ( 
                    <li onClick={() => goToDeathNotice(val)} style={{cursor: "pointer"}}>
                      <div class="row gx-3 deathNotice py-2 align-items-center ">
                        <div class="col-3">
                          <img
                            src={val.profileurl}
                            class="img-fluid rounded-2 h-100 w-100 featureNoticeImage"
                            alt="..."
                          />
                        </div>
                        <div class="col-9">
                          <div class="">
                            <h6 class="small fw-bold">
                              {val.firstname} {val.middlename} {val.lastname}{" "}
                            </h6>
                            <p class="small text-dark fw-bold text-opacity-50 ">
                              {val.stateoforigin}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                      )
                      }
                    }
                  )
                ) : <></>
                }
              </ul>
              <div className="px-3">
                <button
                  type="button"
                  onClick={goToAllDeathNotice}
                  class="commonButton py-3 px-4 w-100 "
                >
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
