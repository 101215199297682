import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Sidebar from "./Layout/Sidebar";
import AdminHeader from "./Layout/AdminHeader";
import createDeathNotice from "../../User/Assets/createDeathNotice.png";
import axios from "axios";
import Swal from "sweetalert2";
import {
  convertDate,
  deathNotice,
  introduction,
  normalDate,
  states,
} from "../../Global/GlobalData";
import { fileRoutes } from "../../Global/FileRoutes";
import { useNavigate } from "react-router-dom";

function PublishDeathNotice() {
  const initialState = {
    noticeid: "",
    notice: "",
    noticetype: "",
    file: [],
    firstname: "",
    lastname: "",
    middlename: "",
    nickname: "",
    stateoforigin: "",
    lga: "",
    dob: "",
    dod: "",
    instaurl: "",
    fburl: "",
    tweeturl: "",
    videourl: "",
    email: "",
    mobilenumber: "",
    relationship: "",
    yourfirstname: "",
    yourlastname: "",
    profile: [],
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [noticeData, setNoticeData] = useState([]);
  const [formData, setFormData] = useState(initialState);
  const [commentData, setCommentData] = useState([]);
  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");
  const navigate = useNavigate();
  let parseData = {};
  if (userData) {
    parseData = JSON.parse(userData);
  }

  useEffect(() => {
    if (token && parseData?.user_role === "AD") {
      getNoticeData();
    } else {
      navigate(fileRoutes.adminLogin);
    }
  }, []);

  const getNoticeData = () => {
    axios
      .post("https://demise.ng/api/demise/admin/noticelist.php", "")
      .then((response) => {
        if (response.status === 200) {
          console.log("Response", response.data);
          setNoticeData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const editNoticeAPI = (noticeId) => {
    let payload = {
      notice_id: noticeId,
    };
    axios
      .post("https://demise.ng/api/demise/notice/notice_detail.php", payload)
      .then((response) => {
        if (response.data.status === "success") {
          setFormData({
            user_id: response.data.notice_datail.user_id,
            notice: response.data.notice_datail.notice,
            noticetype: response.data.notice_datail.noticetype,
            firstname: response.data.notice_datail.firstname,
            lastname: response.data.notice_datail.lastname,
            middlename: response.data.notice_datail.middlename,
            nickname: response.data.notice_datail.nickname,
            stateoforigin: response.data.notice_datail.stateoforigin,
            lga: response.data.notice_datail.lga,
            dob: normalDate(response.data.notice_datail.dob),
            dod: normalDate(response.data.notice_datail.dod),
            fburl: response.data.notice_datail.fburl,
            instaurl: response.data.notice_datail.instaurl,
            tweeturl: response.data.notice_datail.tweeturl,
            imageurl: response.data.notice_datail.imageurl,
            videourl: response.data.notice_datail.videourl,
            yourfirstname: response.data.notice_datail.yourfirstname,
            yourlastname: response.data.notice_datail.yourlastname,
            email: response.data.notice_datail.email,
            mobilenumber: response.data.notice_datail.mobilenumber,
            relationship: response.data.notice_datail.relationship,
            id: response.data.notice_datail.id,
            noticeid: response.data.notice_datail.noticeid,
            profileurl: response.data.notice_datail.profileurl,
            isapproved: response.data.notice_datail.isapproved,
            createddate: response.data.notice_datail.createddate,
            files: response.data.notice_datail.files,
          });
          let parseContent = JSON.parse(response.data.notice_datail.description);
          const data = convertFromRaw(parseContent);
          console.log("Content Data", data);
          const contentData = EditorState.createWithContent(data);
          setEditorState(contentData);
          getComments(response.data.notice_datail.noticeid);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChange = (event) => {
    const { name, type, value, files } = event.target;

    if (name === "photos") {
      // Check if the number of files exceeds the limit
      if (formData.photos.length >= 2) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "You cannot upload more than 2 files",
        });
        return;
      }

      // Handle the file selection logic
      const selectedFiles = Array.from(files);
      setFormData((prevState) => ({
        ...prevState,
        file: [...prevState.file, ...selectedFiles],
      }));
    } else if (name === "profile") {
      const selectedFiles = Array.from(files);
      setFormData((prevState) => ({
        ...prevState,
        profile: [...selectedFiles],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  /* Update Death Notice */
  const createDeathNoticeApi = () => {
    const requestData = new FormData();

    requestData.append("noticeid", formData.user_id);
    requestData.append("notice", formData.notice);
    requestData.append("noticetype", formData.noticetype);
    requestData.append("firstname", formData.firstname);
    requestData.append("lastname", formData.lastname);
    requestData.append("middlename", formData.middlename);
    requestData.append("nickname", formData.nickname);
    requestData.append("stateoforigin", formData.stateoforigin);
    requestData.append("lga", formData.lga);
    requestData.append("dob", convertDate(formData.dob));
    requestData.append("dod", convertDate(formData.dod));
    requestData.append("instaurl", formData.instaurl);
    requestData.append("fburl", formData.fburl);
    requestData.append("tweeturl", formData.tweeturl);
    requestData.append("videourl", formData.videourl);
    // requestData.append("description",editorState);
    requestData.append(
      "description",
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
    requestData.append("yourfirstname", formData.yourfirstname);
    requestData.append("email", formData.email);
    requestData.append("mobilenumber", formData.mobilenumber);
    requestData.append("relationship", formData.relationship);
    requestData.append("yourlastname", formData.yourlastname);
    requestData.append("isadmin", 1);
    requestData.append("noticeid", formData.noticeid);
    axios
      .post("https://demise.ng/api/demise/notice/edit_notice.php", requestData)
      .then((response) => {
        if (response.data) {
          if (response.data.status === "error") {
            Swal.fire({
              icon: "error",
              title: "error",
              text: response.data.message,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Notice Updated Successfully!",
            });
            getNoticeData();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /* Click on Edit Button */
  const editClick = (data) => {
    editNoticeAPI(data.noticeid);
  };

  const getComments = (noticeId) => {
    let payload = {
      noticeid: noticeId,
    };
    axios
      .post(
        "https://demise.ng/api/demise/comments/notice_comments.php",
        payload
      )
      .then((response) => {
        if (response.data.status === "success") {
          setCommentData(response.data.messages);
        }
        else{
          setCommentData([]);
        }
      });
  };

  /* Delete Comment Modal */
  const deleteComment = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCommentApi(data.commentid);
      }
    });
  };

  /* Delete Comment API */
  const deleteCommentApi = (commentId) => {
    let payload = {
      commentid: commentId,
      isdelete: 1,
    };

    axios
      .post("https://demise.ng/api/demise/comments/delete.php", payload)
      .then((response) => {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Comment Deleted",
            text: "Comment has been Deleted!",
          });
          getComments(formData.noticeid);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /* Notice Approve */
  const publishNotice = (data,isApproved) => {
    let payload =  {
      noticeid:data.noticeid,
      isapproved:isApproved
    }
    axios
      .post("https://demise.ng/api/demise/admin/approve_notice.php",payload)
      .then((response) => {
        if (response.data.status === "sucess") {
          Swal.fire({
            icon: "success",
            title: `Notice ${+isApproved !== 0 ? 'Approved!' : 'Revoked!'}` ,
            // text: `Notiec has been ${+isApproved !== 0 ? 'Approved.' : "Revoked"}`,
          });
          getNoticeData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /* Delete Notice Modal */
  const deleteNotice = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteNoticeAPI(data.noticeid);
      }
    });
  };

  /* Delete Notice API */
  const deleteNoticeAPI = (noticeId) => {
    let payload = {
      notice_id: noticeId,
    };

    axios
      .post("https://demise.ng/api/demise/notice/delete_notice.php", payload)
      .then((response) => {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Notice Delete",
            text: "Notice deleted Successfully",
          });
          getNoticeData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <>
      <div className="row bg-white">
        <div className="col-xl-3 col-lg-4 col-md-4 pe-0">
          <Sidebar />
        </div>
        <div className="col-xl-9 col-lg-8 col-md-8 ps-0">
          <AdminHeader />
          <main class="px-4">
            <div className="table-responsive">
              <table class="table mt-lg-5 mt-4">
                <thead>
                  <tr>
                    <td scope="col">Full Name</td>
                    <td scope="col">State</td>
                    <td scope="col">Email </td>
                    <td scope="col">Date Posted</td>
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {noticeData && noticeData.length > 0 ? (
                    noticeData.map((val) => (
                      <tr>
                        <td>
                          {val.firstname} {val.middlename} {val.lastname}
                        </td>
                        <td>{val.stateoforigin}</td>
                        <td>{val.email}</td>
                        <td>{val.createddate}</td>
                        <td>
                          <div className="d-flex gap-2 align-items-center justify-content-center ">
                            <button
                              className="commonButton px-4 py-2 fw-medium"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              onClick={() => editClick(val)}
                            >
                              <i class="fa-solid fa-pencil pe-1 small"></i> Edit
                            </button>
                            <button
                              className="commonButton px-4 py-2 fw-medium"
                              onClick={() => deleteNotice(val)}
                            >
                              <i class="fa-solid fa-trash-can pe-1 small"></i>{" "}
                              Delete
                            </button>
                            {/* if admin had already published it then show text else show button, This notice won't show until admin approves it  */}
                            <button
                              className="commonButton px-4 py-2 fw-medium"
                              hidden={+val.isapproved !== 0}
                              onClick={() => publishNotice(val,1)}
                            >
                              <i class="fa-solid fa-circle-check pe-1 small"></i>{" "}
                              Publish
                            </button>
                            <span
                              className="text-secondary"
                              hidden={+val.isapproved !== 1}
                            >
                              <i class="fa-solid fa-circle-check pe-1 small"></i>
                              Already Published
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-body py-lg-5 py-3">
              <div className="row justify-content-center ">
                <div className="col-12">
                  <h1 className="fw-bold text-center">
                    Death Notice Introduction
                  </h1>
                  <div className="profile">
                    <img
                      src={formData.profileurl}
                      className="img-fluid rounded-3 mx-auto d-block mt-4 mb-3 "
                      width={310}
                      height={300}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12">
                  <form className="row g-4">
                    <div className="col-md-6">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={formData.notice}
                        name="notice"
                        onChange={onChange}
                      >
                        <option value=""></option>
                        {deathNotice.map((val) => (
                          <option value={val}>{val}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={formData.noticetype}
                        onChange={onChange}
                      >
                        {introduction.map((val) => (
                          <option value={val}>{val}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="First Name "
                        value={formData.firstname}
                        name="firstname"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        onChange={onChange}
                        name="lastname"
                        value={formData.lastname}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Middle Name"
                        name="middlename"
                        value={formData.middlename}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Nick Name "
                        name="nickname"
                        value={formData.nickname}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-select"
                        type="text"
                        placeholder="State of Origin"
                        value={formData.stateoforigin}
                        name="stateoforigin"
                        onChange={onChange}
                      >
                        {states.map((val) => (
                          <option value={val}>{val}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      <input
                        class="form-control"
                        placeholder="LGA"
                        aria-label="Default select example"
                        value={formData.lga}
                        name="lga"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          type="date"
                          placeholder="State of Origin"
                          name="dob"
                          value={formData.dob}
                          onChange={onChange}
                        />
                        <label for="floatingTextarea">From</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          className="form-control"
                          type="date"
                          placeholder="State of Origin"
                          value={formData.dod}
                          name="dod"
                          onChange={onChange}
                        />
                        <label for="floatingTextarea">To</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Facebook Link of the deceased (if applicable)"
                        value={formData.fburl}
                        name="fburl"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Twitter Link of the deceased (if applicable)"
                        value={formData.tweeturl}
                        name="tweeturl"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Instagram Link of the deceased (if applicable)"
                        value={formData.instaurl}
                        name="instaurl"
                        onChange={onChange}
                      />
                    </div>
                    {/* <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Upload photos of the deceased (Max limit is 2)"
                      />
                    </div> */}
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add a funeral video or streaming link (if applicable)"
                        value={formData.videourl}
                        name="videourl"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-12">
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your First Name"
                        value={formData.yourfirstname}
                        name="yourfirstname"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Last Name"
                        onChange={onChange}
                        value={formData.yourlastname}
                        name="yourlastname"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        name="email"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Mobile Number"
                        value={formData.mobilenumber}
                        name="mobilenumber"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Your relationship to the deceased"
                        value={formData.relationship}
                        name="relationship"
                        onChange={onChange}
                      />
                    </div>
                    <div className="row justify-content-center ">
                      <div className="col-lg-8">
                        {commentData && commentData.length > 0 ? (
                          commentData.map((val) => (
                            <>
                              <div className="col mt-5 mb-4">
                                <h3 className="fw-bold">Comments</h3>
                              </div>

                              <div className="col mb-4">
                                <label
                                  for="exampleFormControlTextarea1"
                                  class="form-label"
                                >
                                  {val.firstname} {val.lastname}
                                </label>
                                <textarea
                                  class="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                  value={val.message}
                                  disabled={true}
                                ></textarea>
                                <div className=" d-flex justify-content-end">
                                  <button
                                    type="button"
                                    class="commonButton py-2 px-4 mt-3 "
                                    onClick={() => deleteComment(val)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                        <div className="d-flex">
                          <button
                            type="button"
                            class="commonButton py-3 px-4 mt-3 fs-5 w-100 me-3"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="commonButton py-3 px-4 mt-3 fs-5 w-100 ms-3"
                            onClick={createDeathNoticeApi}
                            data-bs-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PublishDeathNotice;
