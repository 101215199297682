import React, { useState } from "react";
import UserHeader from "../../Layout/UserHeader";
import UserFooter from "../../Layout/UserFooter";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../../Global/FileRoutes";

const AdvertiserRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptPolicy: false,
  });
  const [btnDisable, setBtnDisable] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type !== "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const validation = () => {
    if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email Address",
      });
      return false;
    } else if (!formData.password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Password",
      });
      return false;
    } else if (!formData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Confirm Password",
      });
      return false;
    } else if (!formData.acceptPolicy) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Accept Our Terms And Policy",
      });
      return false;
    } else if (formData.password.trim() !== formData.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Your Password And Confirm Password Don't Match",
      });
      return false;
    }
    return true;
  };
  const createAccount = () => {
    const validations = validation();
    if (validations) {
      createAccountAPI();
    }
  };

  const createAccountAPI = () => {
    const requestData = {
      email: formData.email,
      username: formData.userName,
      password: formData.password,
      usertypeid: 3,
    };
    // axios
    //   .post(
    //     "https://demise.ng/api/demise/account/user_register.php",
    //     requestData
    //   )
    //   .then((response) => {
    //     if (response.data.status === "success") {
    //       Swal.fire({
    //         icon: response.data.status,
    //         title: "Success",
    //         text: response.data.message,
    //       }).then((confirm) => {
    //         if (confirm) {
    //           navigate(`${fileRoutes.advertiserLogin}`);
    //         }
    //       });
    //     }
    //   })
    axios
      .post(
        "https://demise.ng/api/demise/account/user_register.php",
        requestData
      )
      .then((response) => {
        if (response.data.status === "success") {
          Swal.fire({
            icon: response.data.status,
            title: "Success",
            text: response.data.message,
          }).then((confirm) => {
            if (confirm) {
              navigate(`${fileRoutes.advertiserLogin}`);
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      })

      .catch((error) => {
        console.log("Error", error);
      });
  };

  /* On Blur */
  const onBlur = (event) => {
    const { name, value } = event.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      Swal.fire({
        text: "Please enter a valid email address",
        title: "Error",
        icon: "error",
      });
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };

  return (
    <>
      <UserHeader />
      <main className="homePage paddingY">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-white rounded-4 p-lg-5 p-4">
                <h1 className="fw-bold fs-4 text-center">
                  Create your account
                </h1>
                <form className="row g-4 mt-3">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User Name *"
                      name="userName"
                      onChange={onChange}
                      value={formData.userName}
                    />
                    <label className="notesText">
                      Please enter your actual name
                    </label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email *"
                      name="email"
                      value={formData.email}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password *"
                        name="password"
                        onChange={onChange}
                        value={formData.password}
                      />
                      <span
                        className="eyeIcon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i class="fa-solid fa-eye"></i>
                        ) : (
                          <i class="fa-solid fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Confirm Password *"
                        onChange={onChange}
                        value={formData.confirmPassword}
                        name="confirmPassword"
                      />
                      <span
                        className="eyeIcon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i class="fa-solid fa-eye"></i>
                        ) : (
                          <i class="fa-solid fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        name="acceptPolicy"
                        checked={formData.acceptPolicy}
                        onChange={onChange}
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                        I accept the terms and conditions and privacy policy of
                        this website.
                      </label>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="commonButton py-3 px-4 w-100 mt-5 fs-5"
                    onClick={createAccount}
                    disabled={btnDisable}
                  >
                    Create Account
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
};

export default AdvertiserRegister;
