import React, { useState } from "react";
import UserHeader from "../../Layout/UserHeader";
import UserFooter from "../../Layout/UserFooter";
import Swal from "sweetalert2";
import axios from "axios";

function ForgotPassword() {
  const [emailAddress, setEmailAddress] = useState("");

  const onChange = (event) => {
    setEmailAddress(event.target.value);
  };
  const [btnDisable,setBtnDisable] = useState(false);
  const validations = () => {
    if(!emailAddress)
    {
      Swal.fire({ 
        icon:'error',
        text:'Please enter your email address',
        title:'Error'
      })
    }
  }


  const forgotPassword = () => {
    const valid = validations();
    if(valid)
    {
      let payload = {
        email:emailAddress
      }
      axios.post("https://demise.ng/api/demise/account/user_forget.php",payload).then((response) => {
        if(response.data.status === "success")
        {
          Swal.fire({
            icon:'success',
            title:"Success",
            text:'Link has been sent to your email address'
          })
        }
        else {
          Swal.fire({
            icon:'error',
            title:"Error",
            text:response.data.message
          })
        }
      }).catch((error) => {
        console.log("error",error);
      })
    }
  }

   /* On Blur */
   const onBlur = (event) => {
    const {name,value} = event.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
if (!emailRegex.test(value)) {
  Swal.fire({
   text:'Please enter a valid email address',
   title:'Error',
   icon:'error'
  })
   setBtnDisable(true);
}
else {
 setBtnDisable(false)
}
  }
  return (
    <>
      <UserHeader />
      <main className="creatorLogin paddingY">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-white rounded-4 p-lg-5 p-4">
                <h1 className="fw-bold fs-4 text-center">Forgot Password</h1>
                <form className="row g-4 mt-3">
                  <div className="col ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={emailAddress}
                      onChange={onChange}
                      onBlur={onBlur}
                      placeholder="Your Email *"
                    />
                    <p className="mt-2 notesText">On submit if user exist an email with link to change your password will be sent to you.</p>
                  </div>

                  <button
                    type="button"
                    class="commonButton py-3 px-4 w-100 mt-5 fs-5"
                    onClick={forgotPassword}
                    disabled={btnDisable}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
}
export default ForgotPassword;
