import React from 'react'
import UserHeader from '../Layout/UserHeader';
import UserFooter from '../Layout/UserFooter';

function CookiePolicy() {
  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12 contentText">
                <h1 class="display-5 fw-semibold mb-5">
                  DEMISE.NG COOKIE POLICY
                </h1>
                <strong>INFORMATION ABOUT OUR USE OF COOKIES</strong>
                <div className="mb-5 mt-3">
                  <p>
                    Demise Affairs Ltd. trading as demise.ng is committed to protecting and respecting your privacy. Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. You can opt out of all cookies on our website except those classed as strictly necessary.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    WHAT IS A COOKIE?
                  </h5>
                  <p>
                    A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive. They allow companies to customise functions of a website for users such as remembering a registered login and also to track movements within a website to see which pages are popular and allow a company to create a better website for its users.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    THE COOKIES WE USE
                  </h5>
                  <p>
                    We use the following cookies:
                  </p>
                <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                  <li className="mb-3">Strictly necessary cookies</li>
                  <p>These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</p>  
                  <li className="mb-3">Preference cookies</li>
                  <p>Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</p>
                  <li className="mb-3">Statistics cookies</li>
                  <p>Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously</p>
                  <li className="mb-3">Marketing   cookies</li>
                  <p>Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</p>
                </ul>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                  HOW TO BLOCK COOKIES
                  </h5>
                  <p>
                  You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site. 
                  </p>
                  <p>You can find more information about the cookies we use and the purposes for which we use them in the table below:</p>
                  <p>Website cookies. This website uses cookies to personalise content, to provide social media features and to analyse our traffic. We also share anonymous information with our analytics partners about how our site is used. You can change your preferences at any time, on our Cookie Policy page. Select your preference by clicking one of the buttons below.</p>
                  <p>Cookies are small text files that can be used by websites to make a user's experience more efficient.</p>
                  <p>The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.</p>
                  <p>This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.</p>
                  <p>You can at any time change or withdraw your consent from the Cookie Declaration on our website.</p>
                  <p>Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.</p>
                  <p>Cookie declaration last updated on 30/01/2024</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  )
}
export default CookiePolicy;
