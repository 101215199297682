import React from "react";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";

function TermsOfUse() {
  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12 contentText">
                <h1 class="display-5 fw-semibold mb-5">
                  DEMISE.NG TERMS OF USE
                </h1>
                <strong>Please read the following Terms of Use carefully before using demise.ng or any website or application powered by Demise Affairs Ltd. (collectively, the "Services").</strong>
                <div className="mb-5 mt-3">
                  <h5 className="fw-semibold mb-3">
                    1.	APPLICABILITY AND ACCEPTANCE OF THESE TERMS OF USE
                  </h5>
                  <p>
                    By downloading, viewing, using, accessing, browsing, or submitting any content or material on or to the Services, you agree to these Terms of Use as a binding legal agreement between you and Demise Affairs Ltd., without limitation or qualification. The term "you" or "You" shall refer to any person or entity who downloads, views, uses, accesses, browses or submits any content or material to the Services.
                  </p>
                  <p>
                    If you do not agree to these Terms of Use, you may not use the Services. Demise Affairs Ltd. reserves the right to modify these Terms of Use at any time without prior notice. You agree that each time you access the Services, you shall be subject to the then-current Terms of Use, and continued use of the Services now or following modifications in these Terms of Use confirms that you have read, accepted, and agreed to be bound by such modifications.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    2.	USER LICENCE
                  </h5>
                  <p>
                    Scope. Demise Affairs Ltd. grants you permission to view the Services and to download, integrate via authorized social media application, email, or print individual pages of the Services in accordance with these Terms of Use and solely for your own personal, non-commercial use, provided you do not remove any trademark, copyright or other notice contained on such pages. No other use is permitted. You may not, for example, incorporate the information, content, or other material in any database or cache. You may not re-publish, transmit, display, perform, reproduce, resell, license, create derivative works from, transfer, or sell any information, content, material, software, products or services obtained through the Service, except as specifically noted above or granted by Demise Affairs Ltd.
                  </p>
                  <p>
                    Except as specifically authorized by Demise Affairs Ltd., you may not access the Services with any robot, spider, web crawler, extraction software, or any other automated process or device to scrape, copy, or monitor any portion of the Services or any information, content, or material from the Services. Demise Affairs Ltd. reserves all of its statutory and common law rights against any person or entity who violates this paragraph. You may not link or frame to any pages of the Services or any content contained therein, whether in whole or in part, except as provided herein.
                  </p>
                  <p>
                    Any rights not expressly granted herein are reserved.
                  </p>
                  <p>
                    User Conduct. You agree that your use of the Services is subject to all applicable local, state, national and international laws and regulations. You also agree:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">to comply with Nigerian and local laws or rules regarding online conduct and acceptable Material, and regarding the transmission of technical data exported through the Services from the country or the country in which you reside;</li>
                    <li className="mb-3">not to host, submit content to or use the Services without the consent of a parent, guardian or educational supervisor if you are under the age of 13 (a "Minor")</li>
                    <li className="mb-3">not to use the Services for illegal purposes;</li>
                    <li className="mb-3">not to commit any acts of infringement on the Services or with respect to Services content;</li>
                    <li className="mb-3">not to use the Services to engage in commercial activities without prior consent from Demise Affairs Ltd.;</li>
                    <li className="mb-3">not to interfere with another person's use and enjoyment of the Services or another entity's use and enjoyment of the Services;</li>
                    <li className="mb-3">not to use the Services for chain letters, junk mail, spamming, or use of distribution lists;</li>
                    <li className="mb-3">not to upload or transmit viruses or other harmful, disruptive or destructive files;</li>
                    <li className="mb-3">not to disrupt, interfere with, or otherwise harm or violate the security of the Services, system resources, accounts, passwords, servers or networks connected to or accessible through the Services or affiliated or linked sites.</li>
                  </ul>
                  <p>Harm from Commercial Use. You agree that the consequences of commercial use or re-publication may be so serious and incalculable that monetary compensation may not be a sufficient or appropriate remedy.</p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    3.	SERVICES USE
                  </h5>
                  <p>
                    Nature of User Material. Some of the Services allow you and others to post, upload, transmit, display, publish, distribute, or otherwise submit material (collectively, "Submit"), including, but not limited to, images, information, articles, illustrations, lyrics, photos, audio files, poems, videos, or text (collectively, "Material") to any portion of the death notice Website, or other purposes. You agree not to Submit any Material that:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">contains vulgar, profane, abusive, hateful, or sexually explicit language, epithets or slurs, text or illustrations in poor taste, inflammatory attacks of a personal, sexual, racial or religious nature, or expressions of bigotry, racism, discrimination or hate;</li>
                    <li className="mb-3">is defamatory, threatening, disparaging, inflammatory, false, misleading, deceptive, fraudulent, inaccurate, or unfair, contains gross exaggeration or unsubstantiated claims, violates the privacy rights or right of publicity of any third party, is unreasonably harmful or offensive to any individual or community, contains any actionable statement, or tends to mislead or reflect unfairly on any other person, business or entity;</li>
                    <li className="mb-3">unfairly interferes with any third party's uninterrupted use and enjoyment of the Services;</li>
                    <li className="mb-3">advertises, promotes or offers to trade any goods or services, except in areas specifically designated for such purpose;</li>
                    <li className="mb-3">is intended primarily to promote a cause or movement, whether political, religious or other;</li>
                    <li className="mb-3">contains copyrighted content (copyrighted articles, illustrations, images, lyrics, photos, audio, video, poems, text, or other content) without the express permission of the content's copyright owner.</li>
                    <li className="mb-3">constitutes, promotes or encourages illegal acts, the violation of any right of any individual or entity, the violation of any local, state, national or international law, rule, guideline or regulation, or otherwise creates liability;</li>
                    <li className="mb-3">disclose any personal identifying information relating to or images of a Minor without consent of a parent, guardian or educational supervisor;</li>
                    <li className="mb-3">infringes any copyright, trademark, patent, trade secret, or other intellectual property right;</li>
                    <li className="mb-3">contains viruses or other harmful, disruptive or destructive files;</li>
                    <li className="mb-3">harms or is inappropriate for minors to view;</li>
                    <li className="mb-3">links to any commercial website;</li>
                    <li className="mb-3">is not otherwise in compliance with these Terms of Use.</li>
                  </ul>
                  <p>User Representations and Warranties. Each time you Submit Material to the Services, you represent and warrant that you have the right to Submit the Material, which means:</p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">you are the author of the Material;</li>
                    <li className="mb-3">the Material is not protected by copyright law;</li>
                    <li className="mb-3">you have express permission from the copyright owner to post the Material to the Services;</li>
                    <li className="mb-3">you have the right to grant Demise Affairs Ltd. the license set out in these Terms of Use;</li>
                    <li className="mb-3">the Material you Submit does not violate these Terms of Use.</li>
                  </ul>
                  <p>User License Grant to Demise Affairs Ltd. You grant Demise Affairs Ltd., its affiliates, and related entities a royalty-free, perpetual, irrevocable, non-exclusive right and license to use, copy, display, archive, store, publish, transmit, perform, distribute, reproduce and create derivative works from all Material you provide to Demise Affairs Ltd. in any form, media, software or technology of any kind now existing or developed in the future. Without limiting the generality of the previous sentence, you authorize Demise Affairs Ltd. to include the Material you provide in a searchable format that may be accessed by users of the Services and other websites. You also grant Demise Affairs Ltd. and its affiliates and related entities the right to use your name and any other information about you that you provide in connection with the use, reproduction or distribution of such Material. You also grant Demise Affairs Ltd. the right to use the Material and any facts, ideas, concepts, know-how or techniques ("Information") contained in any Material or communication you send to us for any purpose whatsoever, including but not limited to, developing, manufacturing, promoting and/or marketing products and services. You grant all rights described in this paragraph in consideration of your use of the Services without the need for additional compensation of any sort to you. Demise Affairs Ltd. does not claim ownership of Material you Submit to the Services.
                    Disclaimer of Responsibility for Material. You acknowledge and agree that Demise Affairs Ltd. does not control the Material Submitted or any other Service permitting user-generated Material or content, and disclaims any responsibility for such Material. Demise Affairs Ltd. specifically disclaims any duty, obligation, or responsibility, to review, screen, refuse to post, remove, or edit any Online death or remembrance notice. In addition, Demise Affairs Ltd. does not represent or warrant that any other content or information accessible via the Services is accurate, complete, or current. Price and availability of information is subject to change without notice. Demise Affairs Ltd. assumes no responsibility or liability for any errors or omissions in the content of the Service.
                  </p>
                  <p>
                    Review & Removal of Material. Demise Affairs Ltd. reserves the right (but disclaims any duty, obligation, or responsibility) to refuse to post, remove in their entirety, or edit (at any time and without prior notice) any Online death or remembrance notice available through the Service for any reason or no reason whatsoever, in its absolute and sole discretion. In cases in which payment has been made for an death notice that has been removed for reasons other than a breach of 2(b), 3(a) or 3(b) above, Demise Affairs Ltd., will issue a full refund.
                  </p>
                  <p>
                    If you believe in good faith that content or Material hosted by the Services infringes your copyright, you (or your agent) may send Demise Affairs Ltd. a notice requesting that the content or Material be removed, or access to it blocked. The notice must include the following information: (a) a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (b) identification of the copyrighted work claimed to have been infringed (or if multiple copyrighted works are covered by a single notification, a representative list of such works); (c) identification of the material that is claimed to be infringing or the subject of infringing activity, and information reasonably sufficient to allow Demise Affairs Ltd. to locate the content or Material within the Services; (d) the name, address, telephone number and email address (if available) of the complaining party; (e) a statement that the complaining party has a good faith belief that use of the content or Material in the manner complained of is not authorized by the copyright owner, its agent or the law; and (f) a statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                  </p>
                  <p>
                    Proprietary Rights. You acknowledge and agree that the Services contain proprietary information and content that is protected by intellectual property and other laws, and may not be used except as provided in these Terms of Use without advance, written permission of Demise Affairs Ltd. All designs, text, graphics, interfaces, and images (and the selection and arrangements thereof), and software, hypertext markup language ("HTML"), scripts, active server pages, and other content and software used in the Services are the property of Demise Affairs Ltd., all rights reserved.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    4.	TERMINATION AND MODIFICATION TO SERVICES
                  </h5>
                  <p>
                    Demise Affairs Ltd. reserves the right (but disclaims any duty, obligation, or responsibility) to refuse to post, remove in their entirety, or edit (at any time and without prior notice) any death notice  available through the Service for any reason or no reason whatsoever, in its absolute and sole discretion. In cases in which payment has been made for a death notice that has been removed for reasons other than a breach of 2(b), 3(a) or 3(b) above, Demise Affairs Ltd., will issue a full refund.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    5.	INDEMNITY
                  </h5>
                  <p>
                    You agree to indemnify and hold Demise Affairs Ltd., its parents, subsidiaries and affiliates, agents, officers, directors, or other employees harmless from any claim, demand, or damage (whether direct, indirect, or consequential, including reasonable attorneys' fees, made by anyone in connection with your use of the Services, with Material or Information you Submit on or through the Services, with any alleged infringement of intellectual property or other right of any person or entity relating to the Services, your violation of these Terms of Use, and any other acts or omissions relating to the Services.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    6.	DISCLAIMER OF WARRANTIES
                  </h5>
                  <p>
                    THE INFORMATION, CONTENT, PRODUCTS, SERVICES, AND MATERIALS PROVIDED BY THE SERVICES (WHETHER PROVIDED BY DEMISE AFFAIRS LTD., YOU, OTHER USERS, AND OTHER AFFILIATES OR THIRD PARTIES), INCLUDING WITHOUT LIMITATION, MATERIAL, TEXT, PHOTOS, GRAPHICS, AUDIO FILES, VIDEO, AND LINKS, ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                  </p>
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, DEMISE AFFAIRS LTD. DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE.
                  </p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    7.	LIMITATION OF LIABILITY
                  </h5>
                  <p>
                    IN NO EVENT SHALL DEMISE AFFAIRS LTD. BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OR DAMAGES WHATSOEVER (EVEN IF DEMISE AFFAIRS LTD. HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER IN AN ACTION UNDER CONTRACT, NEGLIGENCE, OR ANY OTHER THEORY, IN ANY MANNER ARISING OUT OF OR IN CONNECTION WITH THE USE, INABILITY TO USE OR PERFORMANCE OF THE SERVICES. DEMISE AFFAIRS LTD. ASSUMES NO RESPONSIBILITY AND SHALL NOT BE LIABLE FOR ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, BROWSING OF, OR DOWNLOADING OF ANY MATERIAL FROM THE SERVICES. DEMISE AFFAIRS LTD. ASSUMES NO RESPONSIBILITY OR LIABILITY IN ANY MANNER ARISING OUT OF OR IN CONNECTION WITH ANY INFORMATION, CONTENT, PRODUCTS, OR MATERIAL AVAILABLE ON OR THROUGH THE SERVICES, AS WELL AS ANY THIRD-PARTY WEBSITE PAGES OR ADDITIONAL WEBSITES LINKED TO THE SERVICES, FOR ANY ERROR, DEFAMATION, LIBEL, SLANDER, OMISSION, FALSEHOOD, OBSCENITY, PORNOGRAPHY, PROFANITY, DANGER, OR INACCURACY CONTAINED THEREIN. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL DEMISE AFFAIRS LTD.'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE, EXCEED (A) THE AMOUNT PAID BY YOU TO DEMISE AFFAIRS LTD., IF ANY, OR (B) 100,000 NAIRA (WHICHEVER IS LESS).
                  </p>
                  <p>
                    YOU AND DEMISE AFFAIRS LTD. AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE MATERIAL, BARGAINED-FOR BASES OF THIS AGREEMENT, AND THAT THEY HAVE BEEN TAKEN INTO ACCOUNT IN DETERMINING THE CONSIDERATION TO BE GIVEN BY EACH PARTY UNDER THIS AGREEMENT AND IN THE DECISION BY EACH PARTY TO ENTER INTO THIS AGREEMENT. YOU AND DEMISE AFFAIRS LTD. AGREE THAT THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THESE TERMS OF USE ARE FAIR AND REASONABLE.
                  </p>
                  <p>IF YOU ARE DISSATISFIED WITH THE SERVICES OR DO NOT AGREE TO ANY PROVISIONS OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICES, EXCEPT AS MAY BE PROVIDED FOR IN SECTION 7(A) ABOVE.</p>
                </div>
                <div className="mb-5">
                  <h5 className="fw-semibold mb-3">
                    8.	CANCELLATION, TERMINATION, TRANSFER, AND EXPIRATION OF ACCOUNT OR SERVICES
                  </h5>
                  <p>
                    You may cancel or terminate your password, account and/or use of the Services, with or without cause at any time, upon providing written notice to Demise Affairs Ltd. If you cancel or terminate your account or any Services or upon expiration of your account or service, your cancellation/termination/expiration will take effect immediately. Upon termination, your right to use your account or subject Services immediately ceases. Your notice to Demise Affairs Ltd. of cancellation or termination must be sent via email or conventional mail to Demise Affairs Ltd.'s address as set forth in these Terms of Use.
                  </p>
                  <p>
                    Demise Affairs Ltd., in its sole and absolute discretion, and at any time and with or without prior notice to you. may suspend, cancel, transfer, or terminate your password, account, any site within an account and/or use of any Services for any reason whatsoever (including and without limitation, due to lack of use, commercial use, cybersquatting, Demise Affairs Ltd.'s resolution of any dispute among multiple persons claiming the right to use the same or similar accounts or services, or Demise Affairs Ltd.'s belief, in its absolute and sole discretion, that you have violated or acted inconsistently with the letter or spirit of these Terms of Use). In the event of a dispute or conflict among, or complaint from, users of the Services about another's right to establish, use, or maintain an account or Services, Demise Affairs Ltd. reserves for itself the sole right to determine whether or how to resolve such dispute, conflict or complaint, with or without factual or other investigation.
                  </p>
                  <p>
                    Upon expiration, or cancellation or termination by either party, of your account, your password, and/or use of any Services, you will have no right to any Material or Information you Submitted, and Demise Affairs Ltd. may temporarily or permanently block access to, remove, deactivate, delete, and discard all such Material or Information contained therein (including but not limited to any online death notice). Demise Affairs Ltd. accepts no liability for removed or deleted Material or Information. In addition, any contracts (verbal, written, or assumed) with respect to your account, Material or Information you Submit, and/or use of any Services, will be terminated at Demise Affairs Ltd.'s discretion. You agree that Demise Affairs Ltd. shall not be liable to you or any third-party for any termination of your access to any Services.
                  </p>
                  <p>
                    Upon suspension, cancellation, or termination of your account or your use of any Service (for whatever reason), there shall be no refund of money you paid to Demise Affairs Ltd.
                  </p>
                  <p>
                    In connection with demised.ng death notices, the following terms shall also apply:
                  </p>
                  <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
                    <li className="mb-3">The creator and manager of the Website shall be known as the "Site Administrator".</li>
                    <li className="mb-3">The party who pays for the Website shall be known as the "Site Sponsor".</li>
                    <li className="mb-3">Both the Site Administrator and Site Sponsor shall be governed by these Terms of Use.</li>
                    <li className="mb-3">Demise Affairs Ltd. does not screen and is not otherwise obligated to screen Material posted to a death notice Website. Demise Affairs Ltd. reserves the right, however, to remove any Material posted to a death notice Website if said Material does not otherwise comport with these Terms of Use.</li>
                  </ul>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      9.	PERMANENCE OF MATERIAL
                    </h5>
                    <p>
                      Subject to its suspension, cancellation, and termination rights and rights to remove Material, Demise Affairs Ltd. represents that each Online death notice sponsored as "permanent" will remain available through the Services for the duration of Demise Affairs Ltd.'s existence, and that each online death notice sponsored as "one year" will remain available through the Services for one year from the date of sponsorship, unless: (a) the Services cease to exist in whole or in part during the relevant period; or (b) acts or events beyond the reasonable control of Demise Affairs Ltd. cause a deletion, loss of data, failure to store, or other interruption in or termination of the availability of the Services or a death notice that you sponsored, or any other service to which you subscribed. You may at any time ask Demise Affairs Ltd. to delete without charge a death notice that you sponsored or purchased.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      10.	MINORS
                    </h5>
                    <p>
                      The consent of a parent, guardian or educational supervisor shall be required before a Minor can use the Services. Use of the Services is confirmation that the person is an adult or a Minor who has received permission from a parent, guardian or educational supervisor to use the Services. A parent, guardian or educational supervisor will be responsible for any activities of a Minor in connection with the Services regardless of whether the Minor has received permission from the parent, guardian or educational supervisor to use the Services.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      11.	YOUR ACCOUNT, PASSWORD, AND SECURITY
                    </h5>
                    <p>
                      Some elements of the Services (such as the creation of an online death notice) may require you to register and/or create an account with demise.ng. During this process, you must select an account designation and password and provide certain personal information to Demise Affairs Ltd., including a valid email address. In consideration of the use of Demise Affairs Ltd.'s Services, you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the registration form, and (b) maintain and promptly update the personal information you provide to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Demise Affairs Ltd. has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Demise Affairs Ltd. has the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
                    </p>
                    <p>
                      You are responsible for maintaining the confidentiality and security of your account and password, and you are fully responsible for all activities that occur under your password or account, and for any other actions taken in connection with the account or password. You agree to (a) immediately notify Demise Affairs Ltd. of any known or suspected unauthorized use(s) of your password or account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your password or credit card information; and (b) ensure that you exit from your account at the end of each session. Demise Affairs Ltd. will not be liable for any injury, loss or damage of any kind arising from or relating to your failure to comply with (a) and (b) or for any acts or omissions by you or someone else using your account and/or password.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      12.	GOOGLE LIMITED DISCLOSURE
                    </h5>
                    <p>
                      Demise.ng’s use and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      13.	LINKS AND FARMING
                    </h5>
                    <p>
                      As a courtesy to you, the Services may offer links to other websites. Some of these websites are affiliated with Demise Affairs Ltd. while others are not. Demise Affairs Ltd. is not responsible for any content of any website pages created and maintained by organizations independent of Demise Affairs Ltd. If you visit any such third-party websites, you may do so at your own risk. Demise Affairs Ltd. has no control of third-party websites, nor can it guarantee the accuracy of information presented on them. Your use of such information is voluntary, and your reliance on such information should be made only after an independent review. References to commercial products or services within any such third-party website do not constitute or imply an endorsement by Demise Affairs Ltd. By using the Services, you acknowledge that Demise Affairs Ltd. is not responsible for the content or services provided by any third-party websites.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      14.	CONSIDERATION
                    </h5>
                    <p>
                      You acknowledge that these Terms of Use are supported by reasonable and valuable consideration, the receipt and adequacy of which are hereby acknowledged. Without limiting the foregoing, you acknowledge that such consideration includes, without limitation, your use of the Services and receipt or use of data, content, products, services, Material and Information available at or through the Services, the possibility of our review, use or display of your user-generated content, and the possibility of publicity and promotion from our review, use or display of your user-generated content.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      15.	THIRD PARTY SOFTWARE
                    </h5>
                    <p>
                      If you elect to download or access any third-party software that Demise Affairs Ltd. makes available in connection with the Services, you understand that you may have to agree to that third-party provider's terms of use or license before you use such software. You also agree that the use of any third-party software or content obtained in connection with the Services does not transfer to you any rights, title or interest in or to such software or content, and you agree that you will not use any such software except as expressly authorized under that third-party provider's terms of use or license agreement. By downloading software made available through the Services, you are deemed to agree to the third-party provider's terms of use or license agreement, the terms of which are incorporated by reference herein for the benefit of such third-party providers. If you do not agree to the third-party's terms of use or license agreement, do not download its software.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      16.	JURISDICTION, APPLICABLE LAW, AND LIMITATIONS
                    </h5>
                    <p>
                      The Services are created and controlled by Demise Affairs Ltd. You agree that these Terms of Use will be governed by and construed in accordance with the laws of the Federal republic of Nigeria, without regard to its conflicts of law provisions. Use of the Services is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms of Use. Demise Affairs Ltd. makes no claims or assurances that the Services are appropriate or may be downloaded outside of Nigeria. You agree that all legal proceedings arising out of or in connection with these Terms of Use or the Services must be filed in a federal or state court within one year of the time in which the events giving rise to such claim began, or your claim will be forever waived and barred. You expressly submit to the exclusive jurisdiction of said courts and consent to extraterritorial service of process.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h5 className="fw-semibold mb-3">
                      17.	GENERAL
                    </h5>
                    <p>
                      Enforceability. If any portion of these Terms of Use is found to be void, invalid or otherwise unenforceable, then that portion shall be deemed to be superseded by a valid, enforceable provision that matches the intent of the original provision as closely as possible. The remainder of these Terms of Use shall continue to be enforceable and valid according to terms contained herein.
                    </p>
                    <p>
                      Entire Agreement. Except as expressly provided in a particular "Legal Notice" or other notice on particular pages of the Services, these Terms of Use, which hereby incorporate by reference the terms of Demise Affairs Ltd.'s Privacy Policy, constitute the entire agreement between you and Demise Affairs Ltd., superseding all prior agreements regarding the Services.
                    </p>
                    <p>
                      No Waiver. The failure of Demise Affairs Ltd. to exercise or enforce any right or provision of the Terms of Use shall not constitute a waiver of said right or provision. Neither party hereto shall be deemed to be in default of any provision of the Terms of Use or for failure in performance resulting from acts or events beyond the reasonable control of such party and arising without its fault or negligence, including, but not be limited to, acts of God, civil or military authority, interruption of electric or telecommunication services, civil disturbances, acts of war or terrorists, strikes, fires, floods or other catastrophes.
                    </p>
                    <p>Headings & Construction. The section titles in the Terms of Use are for your convenience only and carry no contractual or legal effect whatsoever. The language in these Terms of Use shall be interpreted in accordance with its fair meaning and shall not be strictly interpreted for or against either party.</p>
                    <p>Contact. For purposes of providing notice of cancellation or termination, use the contact form on the Demise.ng website. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  );
}
export default TermsOfUse;
