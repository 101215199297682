import React, { useEffect, useState } from "react";
import logodirectory from "../Assets/logodirectory.png";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import axios from "axios";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";
import { categories, states } from "../../Global/GlobalData";

const ServiceDirectorySearch = () => {
  const [formData, setFormData] = useState({
    state: "",
    category:"",
  });

  const [serviceDirectoryData,setServiceDirectoryData] = useState([]);
  
  const navigate = useNavigate();
  const location = useLocation();
  

  useEffect(() => {
    console.log('Location',location);
      if(location?.state?.data)
      {
        console.log('location',location.state.data);
     
        setFormData((prevState) => ({
          ...prevState,
          category:location.state.data.title1
        }))
        searchDirectoryAPI(location.state.data.title1);
      
      
      }
      else {
        searchDirectoryAPI();
      }
  
  },[])

  
  const searchDirectoryAPI = (category = "") => {
    const payload = {
      state:formData.state && formData.state === "All" ? "" : formData.state,
      category:category || formData.category === "All" ? category : formData.category,
    };
    axios
      .post(
        "https://demise.ng/api/demise/advertiser/search_advertiser.php",
        payload
      )
      .then((response) => {
        if (response.data.status === "success") {
          setServiceDirectoryData(response.data.data);
          window.scrollTo({
            top: 550,
            behavior: "smooth", // Optional: smooth scrolling animation
          });
        }
        else{
          setServiceDirectoryData([]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const validation = () => {
    if (!formData.state) {
      Swal.fire({
        icon: "error",
        title: "error",
        text: "Please Select A State",
      });
      return false;
    }
    if (!formData.category) {
      Swal.fire({
        icon: "error",
        title: "error",
        text: "Please Select A Category",
      });
      return false;
    }
    return true;
  };

  const searchClick = () => {
    const valid = validation();
    if (valid) {
      searchDirectoryAPI();
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const searchDirectoryDetailPage = (advertiser_id) => {
      navigate(fileRoutes.serviceDirectorySingle,{state:{advertiser_id:advertiser_id}})
  }

console.log('formData.category',formData);
  return (
    <>
      <UserHeader />
      <main className="homePage pb-5">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bg-white rounded-4 p-lg-5 p-4">
                  <h1 className="fw-bold fs-4 text-center">
                    Search Service Directory
                  </h1>
                  <form className="row g-4 mt-3 justify-content-center ">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="state"
                          onChange={onChange}
                          value={formData.state}
                        >
                        <option value="">Select States</option>
                          <option value="All">All</option>
                          {
                            states.map((val) =>
                          <option value={val}>{val}</option>
                            )
                        }
                          </select>
                        <label for="floatingTextarea">States</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="category"
                          value={formData.category}
                          onChange={onChange}
                        >
                          <option value="">Select Category</option>
                          <option value="All">All</option>
                          {
                            categories && categories.map((val) => (
                              <option value={val}>{val}</option>
                            ))
                          }
                          </select>
                        <label for="floatingTextarea">Category</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <button
                        type="button"
                        class="commonButton py-3 px-4 mt-3 fs-5 w-100 "
                        onClick={searchClick}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="row" hidden={serviceDirectoryData.length < 1}>
                
                <div className="col text-center ">
                  <h1 class="display-5 fw-bold mb-3">Services Directory</h1>
                </div>
              </div>
        { serviceDirectoryData && serviceDirectoryData.length > 0 ? ( serviceDirectoryData.map((val) => 
        <section className="services-directory-sec mt-3">
          <div className="container">
            
        
            <div className="row">
              <div className="col-12">
            
                <div class="card bg-white rounded-4 p-lg-5 p-4 border-0 mb-3">
                  <div class="row g-0 align-items-center">
                    <div class="col-md-3">
                      <img
                        src={val.profileurl}
                        class="img-fluid w-100 rounded-4 object-fit-cover directoryLogo"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9">
                      <div class="card-body">
                        <h3 class="fw-bold mb-2">{val.companyname}</h3>
                        <p class="fs-5 text-black-50">
                       {val.description ? JSON.parse(val.description).blocks[0].text : ""}
                        </p>
                        <button
                          className="btn border border-secondary-subtle px-lg-5 px-3 mt-3"
                          style={{ backgroundColor: "var-(--body-color)" }}
                          onClick={() => searchDirectoryDetailPage(val.advertiserid)}
                        >
                          View 
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </div>
        </section> 
              )  ) : <h5 className="text-center py-5">No Service Directory Found</h5>
            } 
      </main>
      <UserFooter />
    </>
  );
};

export default ServiceDirectorySearch;
