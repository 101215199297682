import React from "react";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../../../../Global/FileRoutes";

const Index = () => {
  const navigate = useNavigate();
  const createDeathNotice = () => {
    navigate(fileRoutes.createDeathNotice);
  };

  const searchDeathNotice = () => {
    navigate(fileRoutes.deathNoticeArchive);
  };

  return (
    <section class="px-4 text-center hero-sec">
      <div className="container">
        <h1 class="display-5 fw-bold">The Nigerian End of Life Archive</h1>
        <div class="mx-auto mt-md-5 mt-4 col-lg-12 col-xl-9">
          <div class="d-grid gap-3 d-sm-flex justify-content-sm-center">
            <button
              type="button"
              class="lightButton py-3 px-4 w-100 fs-5"
              onClick={createDeathNotice}
            >
              Create a Death or memorial Notice
            </button>
            <button
              type="button"
              class="commonButton py-3 px-4 w-100 fs-5"
              onClick={searchDeathNotice}
            >
              Search A Death or memorial Notice
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
