import React, { memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";
import * as d3 from 'd3';
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";




const MapChart = ({ setTooltipContent, stateData }) => {
  const navigate = useNavigate();
  
  
  const handleMouseEnter = (geo) => {
    const countryData = stateData.find((d) => d.name === geo.properties.name);
    if (countryData) {
      setTooltipContent(countryData);
    } else {
      setTooltipContent(null);
    }
  };
  const fillColor = (geo) => {
    const countryData = stateData.find((d) => d.name === geo.properties.name);
    if (countryData) {
      const colorValue = countryData.projects
      const colorScale = d3.scaleLinear()
        .domain([0, 35])
        .range(["#EFF5F4", "#3BACA2"]);
      return colorScale(colorValue);
    }
    return "rgb(239, 245, 244)";
  };


  const goToDeathNotice = (geo) => {
    console.log('geo',geo.properties.name);
    navigate(fileRoutes.deathNoticeArchive,{state:{data:geo.properties.name}})
  } 

  
  return (
    <div data-tip="">
      <ComposableMap projection="geoMercator"
        projectionConfig={{
          scale: 3000,
        }}>
        <Geographies geography="/features.json">
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onClick={() => goToDeathNotice(geo)}
                onMouseEnter={() => handleMouseEnter(geo)}
                onMouseLeave={() => {
                  setTooltipContent(null);
                }}
                onTouchStartCapture={() => handleMouseEnter(geo)}
                onTouchEndCapture={() => {
                  setTooltipContent(null);
                }}
                onTouchStart={() => handleMouseEnter(geo)}
                onTouchEnd={() => {
                  setTooltipContent(null);
                }}
                style={{
                  default: {
                    fill: fillColor(geo),
                    outline: "none",
                    stroke:"#659D96",
                  },
                  hover: {
                    fill: "#3BACA2",
                    outline: "none",
                    cursor:"pointer"
                  },
                  pressed: {
                    fill: "#3BACA2",
                    outline: "none"
                  }
                }}
                
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
};

export default memo(MapChart);
