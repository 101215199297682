export const fileRoutes = {
  // static 
  home: "/",
  faqs: "/practical-information",
  contactUs: "/contact-us",
  PrivacyPolicy: "/privacy-policy",
  TermsOfUse: "/terms-of-use",
  CookiePolicy: "/cookie-policy",
  AboutUs: "/about-us",

  // creator 
  userLogin: "/user-login",
  userRegister: "/user-register",
  forgotPassword: "/forgot-password",
  userHome: "/your-notices",
  createDeathNotice: "/create-death-notice",
  deathNoticeArchive: "/all-death-notices",
  singleDeathNotice: "/death_notice",
  editNoticeArchive: "/edit-death-notice",

  // advertiser 
  advertiserHome: "/advertiser-profile",
  advertiserLogin: "/advertiser-login",
  advertiserRegister: "/advertiser-register",
  serviceDirectorySearch: "/search-service-directory",
  serviceDirectorySingle: "/service-directory",

  // admin 
  adminLogin: "/admin-login",
  adminHome: "/admin_home",
  publishDeathNotice: "/publish_death_notice",
  AdminChangePass: "/admin-change-password",
};
