import React, { useEffect, useState } from "react";
import Sidebar from "./Layout/Sidebar";
import logoCircle from "../../User/Assets/logoCircle.png";
import AdminHeader from "./Layout/AdminHeader";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";
import Swal from "sweetalert2";
import { categories, states } from "../../Global/GlobalData";
import Multiselect from "multiselect-react-dropdown";
function AdvertiserList() {
  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");
  let parseData = {};
  if (userData) {
    parseData = JSON.parse(userData);
  }
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [gridData, setGridData] = useState([]);
  const [formData, setFormData] = useState({
    id: 0,
    useremail: "",
    userid: "",
    profileurl: "",
    name: "",
    companyname: "",
    state: "",
    category: "",
    description: "",
    ctabtntxt: "",
    ctabtnlink: "",
    imageurl: "",
    phone: "",
    email: "",
    website: "",
    address: "",
    advertiserid: "",
    isapproved: "",
    paystackemail: "",
    files: [],
  });

  const initialState = {
    id: 0,
    useremail: "",
    userid: "",
    profileurl: "",
    name: "",
    companyname: "",
    state: "",
    category: "",
    description: "",
    ctabtntxt: "",
    ctabtnlink: "",
    imageurl: "",
    phone: "",
    email: "",
    website: "",
    address: "",
    advertiserid: "",
    isapproved: "",
    paystackemail: "",
    files: [],
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (token && parseData?.user_role === "AD") {
      advertiserListAPI();
    } else {
      navigate(fileRoutes.adminLogin);
    }
  }, []);

  const advertiserListAPI = () => {
    axios
      .post("https://demise.ng/api/demise/admin/advertiserlist.php", "")
      .then((response) => {
        if (response.data) {
     
          setGridData(response.data.data);
        }
      });
  };

  const setData = (data) => {
    editAdvertiserAPI(data.advertiserid);
  };
  
  const editAdvertiserAPI = (advertiserId) => {
    let payload = {
      advertiser_id: advertiserId,
    };
    axios
      .post(
        "https://demise.ng/api/demise/advertiser/advertiser_detail.php",
        payload
      )
      .then((response) => {
        if (response.data.status === "success") {
          let imageData = [];
          let profileData = [];
          if(response.data.advertiserprofile_datail.files && response.data.advertiserprofile_datail.files.length > 0)
          {
              imageData = response.data.advertiserprofile_datail.files.map((val) => {
                return {
                  name:val.imageurl
                }
              })
          }

          if(response.data.advertiserprofile_datail.profileurl)
          {
            profileData = [{name:response.data.advertiserprofile_datail.profileurl}];
          }

          setFormData({
            id: response.data.advertiserprofile_datail.id,
            useremail: response.data.advertiserprofile_datail.useremail,
            userid: response.data.advertiserprofile_datail.userid,
            profileurl: profileData,
            name: response.data.advertiserprofile_datail.name,
            companyname: response.data.advertiserprofile_datail.companyname,
            state: response.data.advertiserprofile_datail.state && response.data.advertiserprofile_datail.state.length > 0 ? response.data.advertiserprofile_datail.state.split(",") : [],
            category: response.data.advertiserprofile_datail.category && response.data.advertiserprofile_datail.category.length > 0 ? response.data.advertiserprofile_datail.category.split(",") : [],
            ctabtntxt: response.data.advertiserprofile_datail.ctabtntxt,
            ctabtnlink: response.data.advertiserprofile_datail.ctabtnlink,
            imageurl: response.data.advertiserprofile_datail.imageurl,
            phone: response.data.advertiserprofile_datail.phone,
            email: response.data.advertiserprofile_datail.email,
            website: response.data.advertiserprofile_datail.website,
            address: response.data.advertiserprofile_datail.address,
            advertiserid: response.data.advertiserprofile_datail.advertiserid,
            isapproved: response.data.advertiserprofile_datail,
            paystackemail: response.data.advertiserprofile_datail.paystackemail,
            files: imageData,
            isAdmin:1,
          });
          // setEditorState(`<p>${response.data.advertiserprofile_datail.description}</p>`)
          let parseContent = JSON.parse(response.data.advertiserprofile_datail.description)
          const data = convertFromRaw(parseContent)
          const contentData = (EditorState.createWithContent(data));
          setEditorState(contentData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  console.log("Editor state:", editorState);
  /* On Change */
  const onChange = (event) => {
    const { name, value, type, files } = event.target;
    if (type === "file") {
      if (formData.images.length >= 3) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "You cannot upload more than 3 files",
        });
        return;
      }

      // Handle the file selection logic
      const selectedFiles = Array.from(files);
      setFormData((prevState) => ({
        ...prevState,
        images: [...prevState.images, ...selectedFiles],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  /* Validations */
  const validation = () => {
    if (!formData.name) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Name",
      });
      return false;
    } else if (!formData.companyname) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Company Name",
      });
      return false;
    } else if (!formData.state) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your  State",
      });
      return false;
    } else if (!formData.category) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Category",
      });
      return false;
    } else if (!editorState) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Description in Your Editor",
      });
      return false;
    } 
    // else if (!formData.ctabtntxt) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "Please Enter Your CTA Button Text",
    //   });
    //   return false;
    // } 
    // else if (!formData.ctabtnlink) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "Please Enter Your CTA Button Link",
    //   });
    //   return false;
    // }
     else if (!formData.phone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Phone",
      });
      return false;
    } else if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email",
      });
      return false;
    }
    return true;
  };

  /* saveAdvertiser */
  const saveAdvertiser = () => {
    const validations = validation();
    if (validations) {
      const requestData = new FormData();

      // requestData.append("category", formData.category);
      requestData.append("ctabtntxt", formData.ctabtntxt);
      requestData.append("ctabtnlink", formData.ctabtnlink);
      requestData.append("phone", formData.phone);
      requestData.append("useremail", formData.email);
      requestData.append("website", formData.website);
      requestData.append("address", formData.address);
      requestData.append("file[]", formData.files);
      requestData.append("advertiserid", formData.advertiserid);
      requestData.append("companyname", formData.companyname);
      requestData.append("paystackemail",formData.paystackemail);
      requestData.append("description",JSON.stringify(convertToRaw(editorState.getCurrentContent())))
      requestData.append("isadmin",1);
      requestData.append("name",formData.name)
      requestData.append("email",formData.email);
      let state = [...formData.state]

      if(state.length > 0)
      {
        state = state.join(",");
      }
      requestData.append("states",state);

      let categoryData = [...formData.category];
      if (categoryData.length > 0) {
        categoryData = categoryData.join(",");
      }
      requestData.append("category", categoryData);

      axios
        .post(
          "https://demise.ng/api/demise/advertiser/edit_advertiser.php",
          requestData
        )
        .then((response) => {
          if (response.data) {
            if (response.data.status === "error") {
              Swal.fire({
                icon: "error",
                title: "error",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Advertiser Updated Successfully!",
              });

              setFormData(initialState)
          
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };




  /* Approve Service */
  const approveRevokeService = (isApprove,advertiserId) => {
    approveRevokeServiceAPI(isApprove,advertiserId.advertiserid);
  }


  /* Approve Service API */
  const approveRevokeServiceAPI = (isApprove,advertiserId) => {
      let payload = {
        advertiserid:advertiserId,
        isapproved:isApprove ? 1 : 0
      };
      axios.post("https://demise.ng/api/demise/admin/approve_advertiser.php",payload).then((response) => {
        if(response.data.status === "sucess")
        {
          Swal.fire({
            text:`Advertiser has been ${isApprove ? " approved" : " revoked"}.`,
            icon:"success",
            title: isApprove ? 'Approved' : "Revoked"
          });
          advertiserListAPI();
        }
      }).catch((error) => {
        console.log("error",error);
      })
    
  }

  /* Delete Modal */
  const deleteModal = (data) => {
 
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
       deleteApi(data);
      }
    });
      }
    
      const deleteApi = (data) => {
        let payload = {
          advertiserid:data
        }
        axios.post("https://demise.ng/api/demise/admin/delete_advertiser.php",payload).then((response) => {
          if(response.data.status === "sucess")
          {
            console.log("response",response);
            Swal.fire({
              title: "Advertiser Deleted!",
              text: response.data.message,
              icon: "success"
            });
            advertiserListAPI();
          }
        }).catch((error) => {
          console.log("error",error);
        })
      }


        /* Multi Change */
  const multiChange = (event, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };


  return (
    <>
      <div className="row bg-white">
        <div className="col-xl-3 col-lg-4 col-md-4 pe-0">
          <Sidebar />
        </div>
        <div className="col-xl-9 col-lg-8 col-md-8 ps-0">
          <AdminHeader />
          <main class="px-4">
            <div className="table-responsive">
              <table class="table mt-lg-5 mt-4">
                <thead>
                  <tr>
                    <td scope="col">Company Name</td>
                    {/* <td scope="col">Email </td> */}
                    <td scope="col">State</td>
                    {/* <td scope="col">Category</td> */}
                    <td scope="col">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {gridData &&
                    gridData.map((val) => (
                      <tr>
                        <td>{val.companyname}</td>
                        {/* <td>{val.email}</td> */}
                        <td>{val.state}</td>
                        {/* <td>{val.category}</td> */}
                        <td>
                          <div className="d-flex gap-2 align-items-center justify-content-center ">
                            <button
                              className="commonButton px-4 py-2 fw-medium"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              onClick={() => setData(val)}
                            >
                              <i class="fa-solid fa-pencil pe-1 small"></i> Edit
                            </button>
                            <button className="commonButton px-4 py-2 fw-medium" onClick={() => deleteModal(val.advertiserid)}>
                              <i class="fa-solid fa-trash-can pe-1 small"></i>{" "}
                              Delete
                            </button>
                            {/* by default Approve will show if admin verify that they have paid click on approve then revoke button will appear if client want to discontinue their subscription admin will click on revoke and approve will show. Only one button will show at a time */}
                            <button className="commonButton px-4 py-2 fw-medium" onClick={() => approveRevokeService(true,val)} hidden={+val.isapproved !== 0}>
                              <i class="fa-solid fa-circle-check pe-1 small"></i>{" "}
                              Approve
                            </button>
                            <button className="commonButton px-4 py-2 fw-medium" onClick={() => approveRevokeService(false,val)} hidden={+val.isapproved !== 1}>
                              <i class="fa-solid fa-rectangle-xmark pe-1 small"></i>{" "}
                              Revoke
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-body py-lg-5 py-3">
              <form action="">
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src={
                        formData.profileurl.length > 0 ? formData.profileurl[0].name : ""
                      }
                      class="img-fluid w-100 rounded-circle object-fit-cover fixImage"
                      alt="..."
                    />
                    <label className="notesText">Company Logo</label>
                  </div>
                  <div class="col-md-10">
                    <div class="card-body">
                      <h4 class="fw-bold mb-4">Advertiser Profile</h4>
                      <div className="col-12 mb-3">
                        <label>Advertise Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          value={formData.name}
                          name="name"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Company Name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                          value={formData.companyname}
                          name="companyname"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>States *</label>
                        <Multiselect
                          isObject={false}
                          className="form-select py-2"
                          name="state"
                          onSelect={(event) =>
                            multiChange(event, "states")
                          }
                          options={states}
                          placeholder="States"
                          onChange={onChange}
                          selectedValues={formData.state}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Company Services *</label>
                        <Multiselect
                          isObject={false}
                          className="form-select py-2"
                          name="category"
                          onSelect={(event) =>
                            multiChange(event, "category")
                          }
                          options={categories}
                          placeholder="Category"
                          onChange={onChange}
                          selectedValues={formData.category}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>CTA Button Text</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="CTA Button Text"
                      value={formData.ctabtntxt}
                      name="ctabtntxt"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>CTA Button Link</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="CTA Button Link"
                      value={formData.ctabtnlink}
                      name="ctabtnlink"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Company Phone</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Phone"
                      value={formData.phone}
                      name="phone"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Company Email *</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      value={formData.email}
                      name="email"
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Company Website</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Website"
                      name="website"
                      value={formData.website}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Company Address</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Location"
                      value={formData.address}
                      name="address"
                      onChange={onChange}
                    />
                  </div>
                  {/* <div className="col-12 mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email you entered while subscribing on Paystack"
                      value={formData.paystackemail}
                      name="paystackEmail"
                      onChange={onChange}
                    />
                    <label className="notesText text-danger">
                      Email that company used to purchase subscription you need
                      to verify at the Paystack dashboard then click on Approve
                      button to let them advertise their business. (By default it won't advertise their profile)
                    </label>
                  </div> */}
                  <div className="d-flex">
                    <button
                      type="button"
                      class="commonButton py-3 px-4 mt-3 fs-5 w-100 me-3"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="commonButton py-3 px-4 mt-3 fs-5 w-100 ms-3"
                      onClick={saveAdvertiser}
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdvertiserList;
