import React, { useState } from "react";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import axios from "axios";
import Swal from "sweetalert2";

function ContactUs() {

  const [formData,setFormData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    contactNumber:"",
    message:""
  });


  const onChange = (event) => {
    const {name,value} = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:value
    }))
  }

const submitData = () => {
  let payload = 
  {
      email:formData.email,
      first_name:formData.firstName,
      last_name:formData.lastName,
      contact_number:formData.contactNumber,
      message:formData.message
  }
  axios.post("https://demise.ng/api/demise/contact/contact.php",payload).then((response) => {
    if(response.data.status === 'success')
    {
      Swal.fire({
        icon:"success",
        title:"Form Submitted",
        text:"Your Form Has been submitted"
      })
      setFormData({
        firstName:"",
        lastName:"",
        contactNumber:"",
        message:"",
        email: "",
      })
    }
  }).catch((error) => {
    console.log('error',error);
  })

}

  return (
    <>
    <UserHeader />
    <main className="homePage paddingY">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="bg-white rounded-4 p-lg-5 p-4">
              <h1 className="fw-bold fs-4 text-center mb-3">Contact Us</h1>
              <p className="text-center">
                If you would like to make an enquiry, please fill out the form
                below. All information will be treated confidentially.
              </p>
              <form className="row g-4 mt-3 justify-content-center ">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={formData.lastName}
                    name="lastName"
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Contact Number"
                    value={formData.contactNumber}
                    name="contactNumber"
                    onChange={onChange}
                  />
                </div>
                <div class="col-12">
                  <textarea
                    class="form-control"
                    placeholder="Messages"
                    rows="5"
                    value={formData.message}
                    onChange={onChange}
                    name="message"
                  ></textarea>
                </div>
                <div className="col-lg-5">
                  <button
                    type="button"
                    onClick={submitData}
                    class="commonButton py-3 px-4 mt-3 fs-5 w-100 "
                  >
                    Submit
                  </button>
                </div>
              </form>

              <div className="d-flex align-content-center justify-content-center row mt-md-5 mt-3  g-md-4 g-5">
                <div class="d-flex flex-column align-items-center gap-4 col-md-4 border-end border-secondary border-2 cusTomBorder">
                  <i
                    class="fa-solid fa-phone fa-2x"
                    style={{ color: "var(--primary-color)" }}
                  ></i>
                  <small className="fw-medium "><a href="tel:08066343409">08066343409</a></small>
                </div>
                <div class="d-flex flex-column align-items-center gap-4 col-md-4">
                  <i
                    class="fa-solid fa-envelope fa-2x"
                    style={{ color: "var(--primary-color)" }}
                  ></i>
                  <small className="fw-medium "><a href="mailto:info@demise.ng">Info@demise.ng</a></small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <UserFooter />
    </>
  );
}
export default ContactUs;
