import React, { useEffect, useState } from "react";
import serviceIcon from "../Assets/serviceIcon.png";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import axios from "axios";
import { convertDate, deathNotice, states } from "../../Global/GlobalData";
import DeathNoticeCard from "./Components/GlobalComponents/DeathNoticeCard/DeathNoticeCard";
import { useLocation } from "react-router-dom";

function DeathNoticesArchive() {
  const location = useLocation();

  const [searchData, setSearchData] = useState({
    deathNotice: "",
    firstName: "",
    lastName: "",
    stateOfOrigin: "",
    lga: "",
    fromDate: "",
    toDate: "",
  });

  const [serviceCardData, setServiceCardData] = useState([]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setSearchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("location", location);
    if (location?.state?.data) {
      setSearchData((prevState) => ({
        ...prevState,
        stateOfOrigin: location.state.data,
      }));
      searchDeathNotice(location.state.data);
    } else {
      searchDeathNotice();
    }
  }, []);

  const searchDeathNotice = (state = "") => {
    searchDeathNoticeApi(state);
  };

  const searchDeathNoticeApi = (state = "") => {
    const payload = {
      lastname: searchData.lastName,
      firstname: searchData.firstName,
      notice: searchData.deathNotice,
      stateoforigin: state !== "" ? state : searchData.stateOfOrigin,
      lga: searchData.lga,
      from_date: convertDate(searchData.fromDate),
      to_date: convertDate(searchData.toDate),
    };

    axios
      .post("https://demise.ng/api/demise/notice/search_notice.php", payload)
      .then((response) => {
        if (response.data) {
              // Scroll to 500px offset
    window.scrollTo({
      top: 800,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
          console.log("response", response.data);
          setServiceCardData(response.data.data);
        } else {
          setServiceCardData([]);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <>
      <>
        <UserHeader />
        <main className="homePage">
          <section className="paddingY">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="bg-white rounded-4 p-lg-5 p-4">
                    <h1 className="fw-bold fs-4 text-center">
                      Search a Death or Memorial Notice
                    </h1>
                    <form className="row g-4 mt-3 justify-content-center ">
                      <div className="col-12">
                        {/* CHANGE THIS INTO DROPDOWN ALL, DEATH NOTICE, MEMORIAL NOTICE  */}
                        <select
                          type="text"
                          className="form-control"
                          placeholder="Death Notice/Memorial Notice"
                          value={searchData.deathNotice}
                          name="deathNotice"
                          onChange={onChange}
                        >
                          <option value="">All</option>
                          {deathNotice.map((val) => (
                            <option value={val}>{val}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="firstName"
                          value={searchData.firstName}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Last Name "
                          onChange={onChange}
                          value={FormData.lastName}
                          name="lastName"
                        />
                      </div>
                      <div className="col-md-6">
                        <select
                          className="form-select"
                          placeholder="State of Origin"
                          name="stateOfOrigin"
                          onChange={onChange}
                          value={searchData.stateOfOrigin}
                        >
                          <option value="">Select States</option>
                          <option value="All">All</option>
                          {states.map((val) => (
                            <option value={val}>{val}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Default select example"
                          name="lga"
                          value={searchData.lga}
                          onChange={onChange}
                          placeholder="LGA"
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            type="date"
                            placeholder="State of Origin"
                            name="fromDate"
                            value={searchData.fromDate}
                            onChange={onChange}
                          />
                          <label for="floatingTextarea">From</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            type="date"
                            placeholder="State of Origin"
                            value={searchData.toDate}
                            onChange={onChange}
                            name="toDate"
                          />
                          <label for="floatingTextarea">To</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <button
                          type="button"
                          class="commonButton py-3 px-4 mt-3 fs-5 w-100 "
                          onClick={() => searchDeathNotice("")}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="services-sec paddingY">
            <div className="container">
              <div className="row">
                <div className="col text-center ">
                  <h1
                    class="display-5 fw-bold mb-3"
                    hidden={serviceCardData.length < 1}
                  >
                    Death/Memorial Notices
                  </h1>
                </div>
              </div>
              <div className="row mt-5 pt-4 g-4">
                <DeathNoticeCard serviceCard={serviceCardData} />
              </div>
            </div>
          </section>
        </main>
        <UserFooter />
      </>
    </>
  );
}
export default DeathNoticesArchive;
