import HomePage from "./Views/HomePage";
const BasePage = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default BasePage;
