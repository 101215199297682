import React, { useEffect, useState } from "react";
import HeroSection from "./Components/HomepageComponents/HeroSection/index";
import ServiceSection from "./Components/HomepageComponents/ServiceSection/index";
import ListingSec from "./Components/HomepageComponents/ListingSection";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";
import axios from "axios";

function HomePage() {
  const [stateData, setStateData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);

  useEffect(() => {
    getHomeData();
    getNoticeData();
  }, []);

  const getHomeData = () => {
    axios
      .post("https://demise.ng/api/demise/home/home_record.php", "")
      .then((response) => {
        if (response.status === 200) {
          console.log("Response", response.data.data[0]);
          setStateData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getNoticeData = () => {
    axios
      .post("https://demise.ng/api/demise/home/all_notice.php", "")
      .then((response) => {
        if (response.status === 200) {
          console.log("Response", response.data);
          setNoticeData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  return (
    <>
      <UserHeader />
      <main className="homePage">
        <HeroSection />
        <ListingSec noticeData={noticeData} stateData={stateData} />
        <ServiceSection />
      </main>
      <UserFooter />
    </>
  );
}
export default HomePage;
