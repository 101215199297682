import { createBrowserRouter } from "react-router-dom";
import BasePage from "./User/BasePage";
import CreatorLogin from "./User/Views/Auth/CreatorLogin";
import CreatorRegister from "./User/Views/Auth/CreatorRegister";
import { fileRoutes } from "./Global/FileRoutes";
import AdvertiserProfile from "./User/Views/AdvertiserProfile";
import ForgotPassword from "./User/Views/Auth/ForgotPassword";
import AdvertiserRegister from "./User/Views/Auth/AdvertiserRegister";
import AdvertiserLogin from "./User/Views/Auth/AdvertiserLogin";
import UserDeathNotices from "./User/Views/UserDeathNotices";
import AdminLogin from "./Admin/Views/Auth/AdminLogin";
import CreateDeathNotice from "./User/Views/CreateDeathNotice";
import DeathNoticeArchive from "./User/Views/DeathNoticesArchive";
import DeathNoticeSingle from "./User/Views/DeathNoticeSingle";
import EditDeathNotice from "./User/Views/EditDeathNotice";
import ServiceDirectorySearch from "./User/Views/ServiceDirectorySearch";
import ServiceDirectorySingle from "./User/Views/ServiceDirectorySingle";
import AdvertiserList from "./Admin/Views/AdvertiserList";
import PublishDeathNotice from "./Admin/Views/PublishDeathNotice";
import ContactUs from "./User/Views/ContactUs";
import FAQS from "./User/Views/FAQS";
import CookiePolicy from "./User/Views/CookiePolicy";
import PrivacyPolicy from "./User/Views/PrivacyPolicy";
import TermsOfUse from "./User/Views/TermsOfUse";
import AdminChangePassword from "./Admin/Views/Auth/AdminChangePassword";
import AboutUs from "./User/Views/AboutUs";
export const router = createBrowserRouter([
  {
    path: `${fileRoutes.home}`,
    element: <BasePage />,
  },
  {
    path: `${fileRoutes.userLogin}`,
    element: <CreatorLogin />,
  },
  {
    path: `${fileRoutes.userRegister}`,
    element: <CreatorRegister />,
  },
  {
    path: `${fileRoutes.advertiserHome}`,
    element: <AdvertiserProfile />,
  },
  {
    path: `${fileRoutes.forgotPassword}`,
    element: <ForgotPassword />,
  },
  {
    path: `${fileRoutes.advertiserLogin}`,
    element: <AdvertiserLogin />,
  },
  {
    path: `${fileRoutes.advertiserRegister}`,
    element: <AdvertiserRegister />,
  },
  {
    path: `${fileRoutes.userHome}`,
    element: <UserDeathNotices />,
  },
  {
    path: `${fileRoutes.adminLogin}`,
    element: <AdminLogin />,
  },
  {
    path: `${fileRoutes.createDeathNotice}`,
    element: <CreateDeathNotice />,
  },
  {
    path: `${fileRoutes.deathNoticeArchive}`,
    element: <DeathNoticeArchive />,
  },
  {
    path: `${fileRoutes.singleDeathNotice}`,
    element: <DeathNoticeSingle />,
  },
  {
    path: `${fileRoutes.editNoticeArchive}`,
    element: <EditDeathNotice />,
  },
  {
    path: `${fileRoutes.serviceDirectorySearch}`,
    element: <ServiceDirectorySearch />,
  },
  {
    path: `${fileRoutes.serviceDirectorySingle}`,
    element: <ServiceDirectorySingle />,
  },
  {
    path: `${fileRoutes.adminHome}`,
    element: <AdvertiserList />,
  },
  {
    path: `${fileRoutes.publishDeathNotice}`,
    element: <PublishDeathNotice />,
  },
  {
    path: `${fileRoutes.faqs}`,
    element: <FAQS />,
  },
  {
    path: `${fileRoutes.contactUs}`,
    element: <ContactUs />,
  },
  {
    path: `${fileRoutes.PrivacyPolicy}`,
    element: <PrivacyPolicy />,
  },
  {
    path: `${fileRoutes.TermsOfUse}`,
    element: <TermsOfUse />,
  },
  {
    path: `${fileRoutes.CookiePolicy}`,
    element: <CookiePolicy />,
  },
  {
    path: `${fileRoutes.AdminChangePass}`,
    element: <AdminChangePassword />,
  },
  {
    path: `${fileRoutes.AboutUs}`,
    element: <AboutUs />,
  },

]);
