import React from "react";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../../../../Global/FileRoutes";
const Index = ({ serviceCard }) => {
  const navigate = useNavigate();
  const redirectToServiceWithData = (value) => {
    navigate(fileRoutes.serviceDirectorySearch,{state:{data:value,selection:"category"}});
  } 


  return (
    <>
      {serviceCard && serviceCard.map((card, id) => (
        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 d-flex ">
          <div class="card w-100 " key={id}>
            <div class="card-body text-center">
              <h5 class="card-title mt-5 text-darkp pb-2">{card.title1}</h5>
              {/* <h6 className="mb-3">{card.title2}</h6> */}
            </div>
            <div class="position-absolute profile-pic-wrapper">
              <div className="bg-white p-4 rounded-circle ">
                <img src={card.imgSrc} class="img-fluid" width={60} height={60} alt="Profile" />
              </div>
            </div>
            <div class="card-footer text-center py-3">
            <button className="serviceDirectoryBtn" onClick={() => redirectToServiceWithData(card)}>
                {card.btnTitle}
           </button>
            </div>
          </div>
        </div>

      ))}
    </>
  );
};

export default Index;
