import React from "react";
import adminLogo from "../../../Admin/Assets/adminLogo.png";
import { fileRoutes } from "../../../Global/FileRoutes";

const Sidebar = () => {
  return (
    <div class="sidebar p-0 min-vh-100">
      <div
        class="offcanvas-md offcanvas-end pt-md-3"
        tabindex="-1"
        id="sidebarMenu"
        aria-labelledby="sidebarMenuLabel"
      >
        <div class="offcanvas-header">
          <img
            src={adminLogo}
            className="d-md-none d-block img-fluid mx-auto "
            width={150}
            alt=""
            id="sidebarMenuLabel"
          />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body d-md-flex flex-column p-0 overflow-y-auto">
          <img
            src={adminLogo}
            className="d-md-block d-none img-fluid mx-auto "
            width={150}
            alt=""
            id="sidebarMenuLabel"
          />

          <ul class="nav flex-column mt-5 px-4 sideBarList">
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                aria-current="page"
                href={fileRoutes.adminHome}
                id="AdvertiseList"
              >
                <i class="fa-solid fa-user me-2"></i> Advertiser List
              </a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                <i class="fa-solid fa-bell me-2"></i> Death Notice List
              </a>
            </li> */}
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                href={fileRoutes.publishDeathNotice}
              >
                <i class="fa-solid fa-circle-check me-2"></i>Death
                Notice List
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link d-flex align-items-center gap-2" href={fileRoutes.AdminChangePass}>
                <i class="fa-solid fa-lock me-2"></i> Change Password
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
