import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";
import headerLogo from "../Assets/headerLogo1.png";
function UserHeader() {
  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");
  let parseData = {};
  if (userData) {
    parseData = JSON.parse(userData);
  }
  const navigate = useNavigate();
  const yourProfile = () => {
    navigate(fileRoutes.advertiserHome);
  }
  const yourNotice = () => {
    navigate(fileRoutes.userHome);
  }
  const logout = () => {
    navigate(fileRoutes.home);
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
  };
  return (
    <header className="bg-white">
      {/* topbar */}
      <div className="d-flex align-items-center topBar">
        <div className="container text-end userHeaderContainer">
          
            <img className="headerLogo" src={headerLogo} alt="logo" />
          
          {!token ? (
            <a href={fileRoutes.userLogin} className="commonButton">
              User Login
            </a>
          ) : (
            <>
            {/* If user is creator show this button    */}
              <button className="commonButton me-2" onClick={yourNotice} hidden={parseData?.user_role !== 'CR'}>
                Your Notices
              </button>

              {/* if user is advisor show this button    */}
              <button className="commonButton me-2" onClick={yourProfile} hidden={parseData?.user_role !== 'AR'}>
                Your Profile
              </button>
              <button className="commonButton" onClick={logout}>
                User Logout
              </button>
            </>
          )}
        </div>
      </div>
      {/* primaryheader  */}
      <div className="container d-flex justify-content-end align-items-center primaryHeader navbar navbar-expand-md">
        <button
          class="navbar-toggler border-dark "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end mt-md-0 mt-5"
          id="navbarSupportedContent"
        >
          <ul className="d-flex flex-md-row flex-column ">
            <li>
              <a href={fileRoutes.home}>Home</a>
            </li>
            <li>
              <a href={fileRoutes.deathNoticeArchive}>Death Notices</a>
            </li>
            <li>
              <a href={fileRoutes.serviceDirectorySearch}>Services Directory</a>
            </li>
            <li>
              <a href={fileRoutes.AboutUs}>About Us</a>
            </li>
            <li>
              <a href={fileRoutes.faqs}>Practical Information</a>
            </li>
            <li>
              <a href={fileRoutes.contactUs}>Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default UserHeader;
