import React, { useEffect, useState } from "react";
import logoCircle from "../Assets/logoCircle.png";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import UserFooter from "../Layout/UserFooter";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UserHeader from "../Layout/UserHeader";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../Global/FileRoutes";
import { categories, states } from "../../Global/GlobalData";

const AdvertiserProfile = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    state: "",
    category: "",
    textEditorData: "",
    ctaBtnText: "",
    ctaBtnLink: "",
    profile: [],
    selectedProfile:[],
    file:[],
    selectedFile:[],
    uploadedPreviewFile:[],
    phone: "",
    email: "",
    website: "",
    address: "",
    paystackEmail: "",
    isEdit:false,
    advertiserid:""
  });

  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");
  let parseData = {}
  if(userData)
  {
    parseData = JSON.parse(userData);
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate(fileRoutes.advertiserLogin);
    }
    else {
      advertiserEmailAPI();
    }
  },[]);


  /* Advertiser from Email API */
  const advertiserEmailAPI = () => {
   let payload = {
      email:parseData?.user_email
  }

    axios.post("https://demise.ng/api/demise/advertiser/advertiser_by_email.php",payload).then((response) => {
      if(response.data.status === "success")
      {
        console.log('Resposne',response.data.data);
        if(response.data.data && Object.keys(response.data.data.length > 0))
        {
          let imageData = [];
          let profileData = [];
          if(response.data.data.files && response.data.data.files.length > 0)
          {
              imageData = response.data.data.files.map((val) => {
                return {
                  name:val.imageurl
                }
              })
          }

          if(response.data.data.profileurl)
          {
            profileData = [{name:response.data.data.profileurl}]
          }
          console.log('profile',profileData);
        setFormData({
          name: response.data.data.name,
          companyName: response.data.data.companyname,
          state: response.data.data.state.split(","),
          category: response.data.data.category.split(","),
          ctaBtnText: response.data.data.ctabtntxt,
          ctaBtnLink: response.data.data.ctabtnlink,
          selectedFile:imageData,
          selectedProfile:profileData,
          profile:profileData,
          file:imageData,
          phone: response.data.data.phone,
          email:response.data.data.email,
          website: response.data.data.website,
          address: response.data.data.address,
          paystackEmail: response.data.data.paystackemail,
          advertiserid:response.data.data.advertiserid,
          isEdit:true
        })
        console.log("asdfasdf",(JSON.parse(response.data.data.description)));
        // console.log("Converted content state:", contentState);
        let parseContent = JSON.parse(response.data.data.description)
        const data = convertFromRaw(parseContent)
        console.log("Content Data",data);
        const contentData = (EditorState.createWithContent(data));
        setEditorState(contentData)
    
      }
    }
    }).catch((error) => {
      console.log("error",error);
    })
   
  }
  /* On Change */
  const onChange = (event) => {
    const { name, value, type, files } = event.target;
    if (name  === "file") {
      if (formData.selectedFile.length >= 3) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "You cannot upload more than 3 files",
        });
        return;
      }
   
      // Handle the file selection logic
      const selectedFiles = Array.from(files);
      const imageUrl = [{name:URL.createObjectURL(event.target.files[0])}]
      setFormData((prevState) => ({
        ...prevState,
       selectedFile: [...prevState.selectedFile, ...selectedFiles],
      file:[...prevState.file,...imageUrl],

      }));
    }
    else if(name === "profile") 
    {
      const imageUrl = [{name:URL.createObjectURL(event.target.files[0])}]
      const selectedFiles = Array.from(files);
      setFormData((prevState) => ({
        ...prevState,
        selectedProfile:[...selectedFiles],
        profile: [...imageUrl],
      }));
    }
    
    
    else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const onEditorChange = (event) => {
    console.log("event", event);
    setEditorState(event);

  };
console.log("Editore",JSON.stringify(convertToRaw(editorState.getCurrentContent())))
  /* Validations */
  const validation = () => {
    if (!formData.name) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Name",
      });
      return false;
    } 
    else if(formData.selectedProfile && formData.selectedProfile.length < 1)
    {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Upload Profile Image",
      });
      return false; 
    }
    
    else if (!formData.companyName) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Company Name",
      });
      return false;
    } else if (!formData.state) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your  State",
      });
      return false;
    } else if (formData.category && formData.category.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Category",
      });
      return false;
    } else if (!editorState) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Description in Your Editor",
      });
      return false;
    } 
    // else if (!formData.ctaBtnText) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "Please Enter Your CTA Button Text",
    //   });
    //   return false;
    // } else if (!formData.ctaBtnLink) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "Please Enter Your CTA Button Link",
    //   });
    //   return false;
    // }
     else if (!formData.phone) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Phone",
      });
      return false;
    } else if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email",
      });
      return false;
    }
    return true;
  };

  /* Activate Account */
  const activateAccount = () => {
    const validations = validation();

    if (validations) {
      const userData = localStorage.getItem('userData');
      let parseData = {};
      if(userData) {
        parseData = JSON.parse(userData);
      }
      const requestData = new FormData();
   
      if (formData.selectedFile.length > 0) {
        for (let i = 0; i < formData.selectedFile.length; i++) {
          requestData.append("file[]", formData.selectedFile[i]);
        }
      } else {
        requestData.append("file[]", "");
      }
    
  if (formData?.selectedProfile?.length > 0) {
        for (let i = 0; i < formData.selectedProfile.length; i++) {
          requestData.append("profile[]", formData.selectedProfile[i]);

        }
      } else {
        requestData.append("profile[]", "");
      }



      let categoryData = [...formData.category];
      if (categoryData.length > 0) {
        categoryData = categoryData.join(",");
      }
      requestData.append("userid",parseData?.user_id);
      requestData.append("useremail",parseData?.user_email);
      requestData.append("name",formData.name);
      requestData.append("category", categoryData);
      requestData.append("description", editorState);
      requestData.append("ctabtntxt", formData.ctaBtnText);
      requestData.append("ctabtnlink", formData.ctaBtnLink);
      requestData.append("phone", formData.phone);
      requestData.append("email", formData.email);
      requestData.append("website", formData.website);
      requestData.append("address", formData.address);
      requestData.append("companyname",formData.companyName)
      requestData.append("states",formData.state);
      requestData.append("isadmin",0);
      requestData.append("paystackemail",formData.paystackEmail);
      requestData.append("description",JSON.stringify(convertToRaw(editorState.getCurrentContent())))
      axios
        .post(
          "https://demise.ng/api//demise/advertiser/create_advertiser.php",
          requestData
        )
        .then((response) => {
          if (response.data) {
         
            console.log("Response", response.data);
            if(response.data.status === "error")
            {
              Swal.fire({
                icon:"error",
                text:response.data.message,
                title:"Error"
              })
            }
            else {
              Swal.fire({
                icon:"success",
                title:"Success",
                text:"Advertiser has been activated!",
              }).then((confirm) => {
                if(confirm)
                {
                  navigate(fileRoutes.home);
                  // window.open('https://paystack.com/pay/qwhmo6a1w5','_blank', 'rel=noopener noreferrer');
                }
              })
            }
          }
        });
    }
  };

  /* Multi Change */
  const multiChange = (event, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: event,
    }));
  };

  /* Edit Account */ 
  const editAccount = () => {
    const validations = validation();

    if (validations) {
      const userData = localStorage.getItem('userData');
      let parseData = {};
      if(userData) {
        parseData = JSON.parse(userData);
      }
      const requestData = new FormData();
      const imageArray = [];
      if (formData.selectedFile.length > 0) {
        for (let i = 0; i < formData.selectedFile.length; i++) {
          if(formData.selectedFile[i].type)
          {
          requestData.append("file[]", formData.selectedFile[i]);
          }
          else {
            imageArray.push(formData.selectedFile[i].name);
          }
        }
      } else {
        requestData.append("file[]", []);
      }

      if (imageArray.length > 0) {
        requestData.append("imageUrls", imageArray.join(","));
      } else {
        requestData.append("imageUrls", "");
      }

  if (formData?.selectedProfileprofile?.length > 0) {
        for (let i = 0; i < formData.selectedProfile.length; i++) {
          requestData.append("profile[]", formData.selectedProfile[i]);
        }
      } else {
        requestData.append("profile[]", "");
      }



      let categoryData = [...formData.category];
      if (categoryData.length > 0) {
        categoryData = categoryData.join(",");
      }
      requestData.append("userid",parseData?.user_id);
      requestData.append("useremail",parseData?.user_email);
      requestData.append("name",formData.name);
      requestData.append("category", categoryData);
      // requestData.append("description", editorState);
      requestData.append("ctabtntxt", formData.ctaBtnText);
      requestData.append("ctabtnlink", formData.ctaBtnLink);
      requestData.append("phone", formData.phone);
      requestData.append("email", formData.email);
      requestData.append("website", formData.website);
      requestData.append("address", formData.address);
      requestData.append("companyname",formData.companyName)
      requestData.append("states",formData.state);
      requestData.append("isadmin",0);
      requestData.append("paystackemail",formData.paystackEmail);
      requestData.append("description",JSON.stringify(convertToRaw(editorState.getCurrentContent())))
      requestData.append("advertiserid",formData.advertiserid)

      axios
        .post(
          "https://demise.ng/api//demise/advertiser/edit_advertiser.php",
          requestData
        )
        .then((response) => {
          if (response.data) {
            // window.open('https://paystack.com/pay/mfe7qpjgk2','_blank', 'rel=noopener noreferrer');
            console.log("Response", response.data);
            if(response.data.status === "error")
            {
              Swal.fire({
                icon:"error",
                text:response.data.message,
                title:"Error"
              })
            }
            else {
              Swal.fire({
                icon:"success",
                text:"Advertiser has been updated!",
                title:"Success"
              }).then((confirm) => {
                if(confirm)
                {
                  navigate(fileRoutes.home)
                }
              })
              
            }
          }
        });
    }
  }

  const saveData = () => {
    if(!formData.isEdit)
    {
      activateAccount();
    }
    else {
      editAccount();
    }
  }


  const removeImage = (imageIndex) => {
    const filterData = formData.file.filter((val,index) => index !== imageIndex);
    console.log(filterData);
    setFormData((prevState)=> ({
      ...prevState,
      file:[...filterData],
      selectedFile:[...filterData]
    }))
  }





console.log("FormData",formData);
  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="bg-white rounded-4 p-lg-5 p-4">
                  <div class="card border-0">
                    <form action="">
                      <div class="row">
                        <div class="col-md-2">
                          <img
                            src={formData.profile && formData.profile.length > 0 ? formData.profile[0].name : ""}
                            class="img-fluid w-100 rounded-circle fixImage mb-3"
                            alt="Company Logo"
                          />
                          <input
                            type="file"
                            onChange={onChange}
                            name="profile"
                            className="w-100"
                          />
                          {/* <button className="commonButton my-3 py-2 px-4 fw-semibold ">
                            Upload Image
                          </button> */}
                          <label className="notesText">
                            Please upload your company's logo *
                          </label>
                        </div>
                        <div class="col-md-10">
                          <div class="card-body">
                            <h4 class="fw-bold mb-4">Advertiser Profile</h4>
                            <div className="col-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Your Name *"
                                name="name"
                                value={formData.name}
                                onChange={onChange}
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company Name *"
                                value={formData.companyName}
                                onChange={onChange}
                                name="companyName"
                              />
                            </div>
                            <div className="col-12 mb-3">
                              <Multiselect
                                isObject={false}
                                className="form-select py-2"
                                aria-label="Default select example"
                                name="state"
                                options={states}
                                onSelect={(event) =>
                                  multiChange(event, "state")
                                }
                                selectedValues={formData.state}
                                placeholder="States"
                              />
                              <label className="notesText">
                                Please select all the states your company
                                operates
                              </label>
                            </div>
                            <div className="col-12 mb-3">
                              <Multiselect
                                isObject={false}
                                className="form-select py-2"
                                name="category"
                                onSelect={(event) =>
                                  multiChange(event, "category")
                                }
                                options={categories}
                                placeholder="Category"
                                selectedValues={formData.category}
                              />
                              <label className="notesText">
                                Please select all the services your company
                                offers *
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mb-3">
                          <Editor
                            editorState={editorState}
                            onEditorStateChange={(event) => onEditorChange(event)}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            name="description"
                          />
                          <label className="notesText">
                            Please write introduction of your company *
                          </label>
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="CTA Button Text"
                            name="ctaBtnText"
                            value={formData.ctaBtnText}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="CTA Button Link"
                            name="ctaBtnLink"
                            onChange={onChange}
                            value={formData.ctaBtnLink}
                          />
                          <label className="notesText">
                            Where you want to redirect users if they want to
                            contact you
                          </label>
                        </div>
                        <div className="col-12 mb-3">
                          <input className="form-control" type="file" name="file" onChange={onChange} value="" />
                          <label className="notesText">
                            Please upload few introductory images
                          </label>
                          {
                            formData.file && formData.file.length > 0 ? formData.file.map((val,index) => 
                         <>
                         <div className="d-flex filesUploadAd">
                            <i class="fa-solid fa-xmark mt-1 text-danger ms-3" onClick={() => removeImage(index)}></i></div>
                            <p>File Name: {val.name}</p>
                            <img className="uploadedFiles" src={val.name} alt="uploaded file" />
                            </> 
                            )  : <></>
                          
                          }
                          

                      
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Phone *"
                            name="phone"
                            value={formData.phone}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email *"
                            name="email"
                            value={formData.email}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Website"
                            onChange={onChange}
                            name="website"
                            value={formData.website}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address"
                            value={formData.address}
                            name="address"
                            onChange={onChange}
                          />
                        </div>
                        {/* <div className="col-12 mb-3">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Email you entered while subscribing on Paystack"
                            value={formData.paystackEmail}
                            name="paystackEmail"
                            onChange={onChange}
                          />
                          <label className="notesText text-danger">
                            Once you made the payment by clicking on Activate
                            Account please enter the email you used to do the
                            payment above then click on Active Account again. If the
                            payment record exist our team will review and
                            approve your account to advertise your service it
                            will take 12-48 hours to review.
                          </label>
                        </div> */}
                        <div class="mt-3">
                          <button
                            type="button"
                            class="commonButton py-3 px-4 fs-5 w-100"
                            onClick={saveData}
                          >
                           {formData.isEdit ? "Update Profile" : " Activate Account" }
                          </button>
                          <label className="notesText pt-2">Once you submit your details we will review your details and will activate your account in 12-48 hrs. If your profile is already listed on website please ignore this note.</label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  );
};

export default AdvertiserProfile;
