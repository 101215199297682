import React, { useState } from "react";
import UserHeader from "../../Layout/UserHeader";
import UserFooter from "../../Layout/UserFooter";
import { useNavigate } from "react-router-dom";
import { fileRoutes } from "../../../Global/FileRoutes";
import Swal from "sweetalert2";
import axios from "axios";
import { globalData } from "../../../Global/GlobalData";
function CreatorLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [btnDisable,setBtnDisable] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  /* On Change */
  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /* Go To Register */
  const goToRegister = () => {
    navigate(fileRoutes.userRegister);
  };

  const validation = () => {
    if (!formData.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Email Address",
      });
      return false;
    } else if (!formData.password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Enter Your Password",
      });
      return false;
    }
    return true;
  };

  /* Login API */
  const loginAPI = () => {
    const valid = validation();
    if (valid) {
      let payload = {
        email: formData.email,
        password: formData.password,
        usertypeid: 2,
      };
      axios
        .post("https://demise.ng/api/demise/account/user_login.php", payload)
        .then((response) => {
          console.log("Response", response.data);
          if (response.data.status === "success") {
            localStorage.setItem("token", response.data.token);
            let userData = {
              user_id:response.data.user_id,
              user_name:response.data.user_name,
              user_email:response.data.user_email,
              user_role:response.data.user_role
            }
            localStorage.setItem("userData",JSON.stringify(userData));
            
            navigate(fileRoutes.userHome);
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message,
            })
          }
        });
    }
  };

  /* Login Button */
  const login = () => {
    loginAPI();
  };


   /* On Blur */
   const onBlur = (event) => {
    const {name,value} = event.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
if (!emailRegex.test(value)) {
  Swal.fire({
   text:'Please enter a valid email address',
   title:'Error',
   icon:'error'
  })
   setBtnDisable(true);
}
else {
 setBtnDisable(false)
}
  }

  return (
    <>
      <UserHeader />
      <main className="creatorLogin paddingY">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-white rounded-4 p-lg-5 p-4">
                <h1 className="fw-bold fs-4 text-center">
                  Log in to your account
                </h1>
                <form className="row g-4 mt-3">
                  <div className="col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email *"
                      name="email"
                      onChange={onChange}
                      value={formData.email}
                      onBlur={onBlur}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Password *"
                        name="password"
                        onChange={onChange}
                        value={formData.password}
                      />
                      <span
                        className="eyeIcon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i class="fa-solid fa-eye"></i>
                        ) : (
                          <i class="fa-solid fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                      />
                      <label className="form-check-label" htmlFor="gridCheck">
                        Remember me
                      </label>
                    </div>
                    <div>
                      <a
                        href={fileRoutes.forgotPassword}
                        className="text-dark fw-semibold"
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="commonButton py-3 px-4 w-100 mt-5 fs-5"
                    onClick={login}
                    disabled={btnDisable}
                  >
                    Log in
                  </button>
                  <div class="separators-x mt-md-5 my-4">
                    Don't have an account yet?
                  </div>
                  <button
                    type="button"
                    class="outlineButton py-3 px-4 w-100 fs-5"
                    onClick={goToRegister}
                  >
                    Register Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <UserFooter />
    </>
  );
}

export default CreatorLogin;
