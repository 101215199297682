import React from "react";
import UserHeader from "../Layout/UserHeader";
import UserFooter from "../Layout/UserFooter";

function AboutUs() {
  return (
    <>
      <UserHeader />
      <main className="homePage">
        <section className="paddingY">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 class="display-5 fw-semibold mb-5">About Us</h1>
                <div className="contentText">
                  <p>Demise.ng is dedicated to support people coping with the grief of losing a loved one. It aims to honor the memory and legacy of the departed by offering a dedicated area where their lives and contributions can be celebrated and remembered.</p>
                  <p>
                    As a comprehensive End of Life archive for Nigeria, the site
                    serves as a centralised platform for families across the
                    globe to record and remember their loved ones. The site is
                    user-friendly, categorising death notices by Nigerian
                    states, easily navigable through the hyperlinked map on the
                    homepage. This feature fosters a cultural connection, vital
                    for those who seek to trace their family roots and heritage
                    for generations to come.
                  </p>
                  <p>
                    The platform also functions as a digital memorial, allowing
                    for the deceased to be documented and remembered, regardless
                    of how long ago they passed. The condolence section acts as
                    an online family register, connecting those in mourning with
                    a wider community who share their grief, offering solace and
                    support.
                  </p>
                  <p>
                    Understanding the complexities of grief and the
                    practicalities of funeral arrangements, Demise.ng provides
                    an array of services to assist in this process. The
                    practical information section is rich with resources,
                    offering guidance on bereavement support, tips for crafting
                    eulogies, religious scriptures for people of Christian and
                    Muslim faiths among other resources.
                  </p>
                  <p>
                    Furthermore, the services directory lists a variety of
                    funeral-related businesses, including florists, casket
                    providers, monument makers, and more, all organised by
                    Nigerian states for ease of access.
                  </p>
                  <p>
                    Creating a death or remembrance notice on Demise.ng is
                    straightforward. Users can set up an account, ensure they
                    have the deceased family's consent, and proceed to craft a
                    notice. This can include details of the departed, links to
                    their social media profiles, and even a funeral live stream
                    link, if available. All submissions undergo a review by our
                    dedicated team to ensure respect and sensitivity before they
                    are published.
                  </p>
                  <p>
                    Demise.ng, is committed to being a guardian of memories,
                    dedicated to ensuring that the stories and legacies of those
                    gone before us are preserved with dignity and cherished for
                    years to come.
                  </p>
                  <p>
                    Demise.ng follows best practices for privacy and data
                    protection.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <UserFooter />
    </>
  );
}
export default AboutUs;
